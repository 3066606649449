import { useEffect, useState, useRef, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';

import Dropzone, { IDropzoneProps, ILayoutProps } from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Image, Shimmer } from 'react-shimmer'
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal'
import { history } from "../../../shared/_helpers/history";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { useWindowSize } from "../../../hooks/useWindowSize";
import { WithContext as ReactTags } from 'react-tag-input';
import { InAppTemplate } from "../../../shared/templates/inapp";
import { allowNumbersOnly, numberWithCommas, decimalNumber } from "../../../shared/utils"
import CaretDn from "../../../assets/caret-dn.svg"
import AddImg from "../../../assets/add-img.svg"
import UploadIcon from "../../../assets/icons/upload.svg"
import TrashIcon from "../../../assets/icons/ic_delete.svg"
import DoneIcon from "../../../assets/icons/done.svg"
import RemoveIcon from "../../../assets/icons/remove.svg"
import SearchIco from "../../../assets/icons/search.svg"
import LeftArrow from "../../../assets/icons/left-arrow.png"
import "../index.scss"
import { AlertMsg } from "../../../shared/components/alert-msg"
import { LoadingItem } from "../../../shared/components/loading"
import { storeActions } from "../../../redux/actions/in-app"

import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";

const uploadImagesData: any[] = [];
const sizeofFetch = 8;

const getMetadata = async ({ pageProps, payload }: any) => {

    await pageProps.getMetadataAction(payload);
}

const addNewProduct = async ({ pageProps, payload }: any) => {

    await pageProps.createProductAction(payload);
}

const uploadProductImage = async ({ pageProps, payload, otherNames }: any) => {

    await pageProps.uploadProductImageAction(payload, otherNames);
}


const getImagesGallery = async ({ pageProps, payload }: any) => {

    await pageProps.getImageGalleryAction(payload, false);
}

const getMoreImages = async ({ pageProps, payload }: any) => {

    await pageProps.getImageGalleryAction(payload, true);
}


const ProductImagePopUp = ({ pageProps, showImagesModal, catListItems, hideProductImgModal }: any) => {

    const [categoryChosen, setCategoryChosen] = useState<any>();
    const [searchTerm, setSearchTerm] = useState("");
    const [categoryToUpload, setUploadCategory] = useState<any>();
    const [productUploadName, setUploadProductName] = useState("");
    const productUploadNameRef = useRef<any>(null);
    const [productUploadOtherNames, setUploadProductOtherNames] = useState<any>([]);
    const [uploadOtherNamesTags, setUploadOtherNamesTags] = useState<any>([]);
    const [productNameError, setProductNameError] = useState(true);
    const [productCatError, setProductCatError] = useState(true);
    const [getImagesUploaded, setImagesUploadedStore] = useState<any[]>([]);
    const [imageData, setImageUploadData] = useState<any>();
    const [hasImageUploaded, setHasImageUploaded] = useState(false);
    const [showUploadAnother, setShowUploadAnother] = useState(false);




    let imagesChosen: any = [];

    useEffect(() => {

        if (imageData) {
            const imageUploadData = new FormData();
            imageUploadData.append("ProductName", imageData.ProductName);
            imageUploadData.append("CategoryName", imageData.CategoryName);
            imageUploadData.append("ImageFile", imageData.ImageFile);
            imageUploadData.append("OtherNames", imageData.OtherNames);



            uploadProductImage({ pageProps, payload: imageUploadData, otherNames: imageData.OtherNames })
                .then(() => {

                    setHasImageUploaded(true)

                })

        }


    }, [imageData])


    useEffect(() => {
        if (hasImageUploaded && pageProps.updateProductImageRequest.request_status === "UPLOAD_PRODUCT_PICTURE_SUCCESS") {

            let imageLink = pageProps.updateProductImageRequest.request_data;

            imagesChosen = [...getImagesUploaded]
            imagesChosen.push({
                index: getImagesUploaded.length + 1,
                url: imageLink.response.fileURL,
                otherNames: [...imageLink?.response?.otherNames]
                // url: imageLink.response.thumbnail
            })

            let imagesList = [...getImagesUploaded]
            imagesList.push({
                index: getImagesUploaded.length + 1,
                url: imageLink.response.fileURL,
                otherNames: [...imageLink?.response?.otherNames]
                // url: imageLink.response.thumbnail
            })

            setImagesUploadedStore(imagesList)
           
            setHasImageUploaded(false)
            setShowUploadAnother(true)
        }
    }, [hasImageUploaded])

    useEffect(() => {
        if (categoryToUpload && productCatError === true) {
            setProductCatError(false)
        }
    }, [categoryToUpload])

   


    const handleDelete = (i: any) => {
       
        setUploadOtherNamesTags(uploadOtherNamesTags.filter((tag: any, index: any) => index !== i))
    }

    const handleAddition = (tag: any) => {

        if (productUploadOtherNames.indexOf(tag?.text) === -1) {
            setUploadOtherNamesTags([...uploadOtherNamesTags, tag])
            setUploadProductOtherNames([...productUploadOtherNames, tag?.text])
        }
        // this.setState({ tags: [...tags, { id: tags.length + 1, text: tag }] });
    }

    const transformImageName = (image: any) => {
        let filename = image;

        if (filename.indexOf("upload-file/") > -1) {
            filename = image.split("upload-file/")[1];
        }
        if (filename.indexOf("thumbnails/") > -1) {
            filename = image.split("thumbnails/")[1];
        }


        return filename;
    }
    const removeImageFromUploads = (chosenImg: any) => {
        let imagesList = [...getImagesUploaded];
        imagesList = imagesList.filter((itemData: any) => {
            return itemData.url !== chosenImg.url
        });
        setImagesUploadedStore(imagesList)
    }

    const saveSearchFilter = (searchTerm: any) => {
        setCategoryChosen({
            label: null,
            value: null
        })
        let payload = `page=1&size=${sizeofFetch}${searchTerm ? `&fileName=${searchTerm}` : ""}`;
        getImagesGallery({ pageProps, payload })
    }


    const saveChosenCat = (chosenCat: any) => {
        setSearchTerm("")
        setCategoryChosen(chosenCat)
        let payload = `page=1&size=${sizeofFetch}${chosenCat?.label ? `&fileName=${chosenCat.label}` : ""}`;
        getImagesGallery({ pageProps, payload })
    }

    const saveUploadCat = (chosenCat: any) => {
        setUploadCategory(chosenCat)
    }

    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];


    const ImageUploadBox = () => {


        const getUploadParams: IDropzoneProps['getUploadParams'] = () => {
            return { url: 'https://httpbin.org/post' }
            // return { url: 'https://httpbin.org/post' }
        }

        const handleChangeStatus: IDropzoneProps['onChangeStatus'] = ({ meta }, status) => {

        }


        const handleSubmit: IDropzoneProps['onSubmit'] = (files: any, allFiles) => {

            // if (productUploadName) {
            //     setProductNameError(false)
            // } else {
            //     setProductNameError(true)
            // }

            // if (categoryToUpload) {
            //     setProductCatError(false)
            // } else {
            //     setProductCatError(true)
            // }

            if (!productNameError && !productCatError) {
                // setProductNameError(true)

                let imageDataToUpload = {
                    // ProductName: productUploadName,
                    ProductName: productUploadNameRef?.current?.value,
                    CategoryName: categoryToUpload.value,
                    ImageFile: files[0].file,
                    OtherNames: productUploadOtherNames
                }




                setImageUploadData(imageDataToUpload)


            }
            // else {
            //     setProductNameError(true)
            // }

            // allFiles.forEach((f) => f.remove())
        }

        return (
            <div className={!productUploadNameRef?.current?.value || productCatError ? "upload_img_wrap disabled_wrap" : "upload_img_wrap"}>
                <Dropzone
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    accept="image/*"
                    maxFiles={1}
                    // disabled={productNameError || productCatError}
                    multiple={false}
                    canRestart={false}
                    canCancel={false}
                    // autoUpload={false}
                    submitButtonContent="Upload image"
                    inputContent={() => {
                        return (<div className="upload_cnt upload_msg" key={30}>
                            <img src={UploadIcon} alt="" />

                            {!showUploadAnother && <div className="upload-txt">Upload photo here</div>}
                            {showUploadAnother && <div className="upload-txt">Upload another photo here</div>}
                            <div className="or-txt">or</div>
                            <div className="upload-cta">Browse photo</div>
                        </div>)
                    }}
                    styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
                />
            </div>
        )
    }



    return (
        <Modal className="upload_images_wrap" show={showImagesModal} onHide={hideProductImgModal}>
            <Modal.Header closeButton>
                <div className="">Add product image </div>
            </Modal.Header>
            <Modal.Body>
                <Tab.Container defaultActiveKey="mami_gallery">
                    <Nav className="other_productinfo_tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="upload_now">Upload Image</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="mami_gallery">MamiMarket Gallery</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className="other_productinfo_tabs_content">
                        <Tab.Pane eventKey="upload_now">
                            <div>
                                <div className="product-info image-info-details">
                                    <div className="two-sided">
                                        <div className="form-group">
                                            <input placeholder="Product name"
                                                onChange={(e: any) => {



                                                    // setUploadProductName(e.target.value)
                                                }}
                                                onBlur={(e: any) => {
                                                    if (productUploadNameRef?.current?.value.length < 1) {
                                                        // if (e.target.value.trim().length < 1) {
                                                        setProductNameError(true)
                                                    } else {
                                                        setProductNameError(false)
                                                    }
                                                }}
                                                type="text"
                                                ref={productUploadNameRef}
                                                // value={productUploadName}
                                                name="" id=""
                                                className="input-wrap" />
                                            {/* {productNameError &&
                                                <div className="form-input-error" >Required</div>
                                            } */}
                                        </div>

                                        <div className="form-group select-cat-wrap">
                                            <Select
                                                value={categoryToUpload}
                                                onChange={saveUploadCat}
                                                options={catListItems}
                                                placeholder="Category"
                                                className="select-wrap"
                                            // ref={catChosenRef}
                                            />
                                            {/* <select name="" id="">
                                                <option value="">Choose a category</option>
                                                {
                                                    pageProps.getProductMetaDataRequest.request_data.response.categories.map((catItem: any, index: any) => {
                                                        return (
                                                            <option key={index}>{catItem.name}</option>
                                                        )
                                                    })
                                                }
                                            </select> */}
                                            {/* {productCatError &&
                                                <div className="form-input-error">Required</div>
                                            } */}

                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <ReactTags
                                            delimiters={delimiters}
                                            tags={uploadOtherNamesTags}
                                            handleDelete={handleDelete}
                                            handleAddition={handleAddition}
                                            classNames={{
                                                tagInput: 'tagInputClass',
                                                tagInputField: 'input-wrap'
                                            }}
                                            placeholder="Other Names of this upload. e.g: Name1, Name2, Name3"
                                        />
                                        {/* <input placeholder="Other Names of this upload. e.g: Name1, Name2, Name3"
                                            onChange={(e: any) => {
                                                if (e.target.value.trim().length >= 1) {
                                                    setUploadProductOtherNames(e.target.value)
                                                }


                                            }}
                                            type="text"
                                            value={productUploadOtherNames}
                                            name="" id=""
                                            className="input-wrap" /> */}
                                        <span className="info-txt">**Type Other Name and press enter</span>

                                    </div>

                                </div>
                                {/* {(productUploadNameRef?.current?.value || productCatError) && */}
                                {(productNameError || productCatError) &&
                                    <div className="form-input-error text-center">Provide product image name and category before upload</div>
                                }
                                <ImageUploadBox />


                                {pageProps.updateProductImageRequest.request_status === "UPLOAD_PRODUCT_PICTURE_SUCCESS" &&
                                    <>

                                        {getImagesUploaded.map((eachImage: any, index: any) => (
                                            <div className="progress-indicator" key={index}>
                                                <div className="uploading">
                                                    <div className="file-progress-wrap">
                                                        <div className="filename">{transformImageName(eachImage.url)}</div>
                                                    </div>
                                                    <div className="status-icons">
                                                        <div className="remove-now"
                                                            onClick={() => {
                                                                removeImageFromUploads(eachImage)
                                                            }}
                                                        ><img src={RemoveIcon} alt="" />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        )
                                        }
                                    </>
                                }
                            </div>
                            {/* <div className="progress-indicator">
                                <div className="uploading">
                                    <div className="file-progress-wrap">
                                        <div className="filename">Tungchivo-avatar.png</div>
                                    </div>
                                    <div className="status-icons">
                                        <div className="remove-now"><img src={RemoveIcon} alt="" /></div>

                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="progress-indicator">
                                <div className="uploading">
                                    <div className="file-progress-wrap">
                                        <div className="filename">Tungchivo-avatar.png</div>
                                        <div className="file-progress">
                                            <div className="percent-width"></div>
                                        </div>
                                        <div className="size-percent">
                                            <div className="sizetxt">428KB</div>
                                            <div className="percent-txt">70%</div>
                                        </div>
                                    </div>
                                    <div className="status-icons">
                                        <div><img src={DoneIcon} alt="" /></div>
                                        <div><img src={RemoveIcon} alt="" /></div>

                                    </div>
                                </div>
                                <div className="uploaded">
                                    <div className="filename-txt">Tungchivo-avatar.png</div>
                                    <div className="size-msg">
                                        428KB
                                        <img src={RemoveIcon} alt="" />
                                    </div>
                                </div>
                            </div> */}
                        </Tab.Pane>
                        <Tab.Pane eventKey="mami_gallery">
                            <div className="search-gallery">
                                <div className="each-filter for-search">
                                    <input type="text"
                                        placeholder="Enter Product title or category"
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value)
                                        }}
                                        onKeyDown={(e: any) => {
                                            if (e.key === 'Enter') {
                                                // 👇️ your logic here

                                                saveSearchFilter(e.target.value.trim())
                                            }
                                        }}
                                    // onBlur={(e) => {
                                    //     saveSearchFilter(e.target.value.trim())
                                    // }} 
                                    />
                                    <button>
                                        <img src={SearchIco} alt="" />
                                    </button>
                                </div>
                                <div className="help-txt">Enter search and press enter</div>
                                {(pageProps.getImageGalleryRequest?.galleryData && pageProps.getImageGalleryRequest?.galleryData.length >= 1) &&
                                    <div>
                                        <div className="gallery-head">
                                            <h3>MamiMarket Gallery</h3>
                                            <div className="gallery-txt">Choose from thousands of stunning images..</div>
                                            {/* {
                                                pageProps.getImageGalleryRequest?.request_data?.response?.pageNumber
                                            }
                                            {
                                                pageProps.getImageGalleryRequest?.request_data?.response?.totalNumberOfPages
                                            } */}
                                        </div>
                                        <div className="filter-wrap">
                                            <label htmlFor="">Filter by:</label>
                                            <div className="filter-options">
                                                <Select
                                                    value={categoryChosen}
                                                    onChange={saveChosenCat}
                                                    options={catListItems}
                                                    placeholder="Category"
                                                    className="select-wrap"
                                                />


                                            </div>
                                        </div>
                                        <div id="scrollableDiv" style={{ height: 300, overflow: "auto" }}>
                                            <InfiniteScroll
                                                dataLength={pageProps.getImageGalleryRequest?.request_data?.response?.totalNumberOfPages * sizeofFetch}
                                                // dataLength={pageProps.getImageGalleryRequest.request_data.response.totalNumberOfRecords}
                                                next={() => {

                                                    let payload = `page=${pageProps.getImageGalleryRequest?.request_data?.response?.totalNumberOfPages + 1}&size=${sizeofFetch}`;
                                                    if (categoryChosen?.label) {
                                                        payload = `${payload}&fileName=${categoryChosen?.label}`;
                                                    }
                                                    if (searchTerm) {
                                                        payload = `${payload}${searchTerm ? `&fileName=${searchTerm}` : ""}`;
                                                    }


                                                    getMoreImages({ pageProps, payload })
                                                }}
                                                // hasMore={true}
                                                hasMore={
                                                    pageProps.getImageGalleryRequest?.request_data?.response?.pageNumber > pageProps.getImageGalleryRequest?.request_data?.response?.totalNumberOfPages
                                                }
                                                loader={<LoadingItem />}
                                                scrollableTarget="scrollableDiv"
                                            >
                                                {pageProps.getImageGalleryRequest?.galleryData.map((image: any, index: any) => (
                                                    // {pageProps.getImageGalleryRequest?.request_data?.response?.results.map((image: any, index: any) => (
                                                    <div className={`each-image each-image-xy-${index} `} key={index}
                                                        // style={{
                                                        //     backgroundImage: `url(${image.imageUrl})`
                                                        // }}
                                                        onClick={(e) => {

                                                            imagesChosen = [...getImagesUploaded];
                                                            if (!imagesChosen.find((eachImage: any) => eachImage.url === image.imageUrl)) {

                                                                imagesChosen.push({
                                                                    index,
                                                                    url: image.imageUrl,
                                                                    othernames: image?.otherNames ? [...image?.otherNames] : []
                                                                })
                                                                setImagesUploadedStore(imagesChosen)
                                                            } else {
                                                                removeImageFromUploads(image.imageUrl)
                                                                // imagesChosen = imagesChosen.filter((itemData: any) => {
                                                                //     return itemData.index !== index;
                                                                // });
                                                            }


                                                            document.querySelector(`.each-image-xy-${index}`)?.classList?.toggle("chosen-image")

                                                        }}>

                                                        <img
                                                            src={image.imageUrl} alt=""
                                                            className="gallery-img" />

                                                    </div>
                                                ))}

                                            </InfiniteScroll>
                                        </div>

                                    </div>
                                }
                                {pageProps.getImageGalleryRequest.request_status === "GET_IMAGE_GALLERY_PENDING" &&
                                    <LoadingItem />
                                }
                                {pageProps.getImageGalleryRequest.request_status === "GET_IMAGE_GALLERY_FAILURE" &&
                                    <AlertMsg type="error" message={pageProps.getImageGalleryRequest?.request_data.error} />
                                }
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>


            </Modal.Body>
            {(pageProps.getImageGalleryRequest.request_status === "GET_IMAGE_GALLERY_SUCCESS" ||
                pageProps.getImageGalleryRequest.request_status === "GET_IMAGE_GALLERY_NEWBATCH") &&
                <Modal.Footer>
                    <button onClick={() => {
                        // if (imagesChosen.length >= 1) {
                        //     // setSelectedImagesStore(imagesChosen)
                        //     hideProductImgModal()
                        // }
                        // uploadImagesData.length=1;

                        uploadImagesData.push(...getImagesUploaded)
                        hideProductImgModal();

                    }}> Add to Product</button>
                </Modal.Footer>
            }
        </Modal>
    )
}


const MainHeader = () => {
    return (
        <div className="product-info-header">
            <div className="left-nav" onClick={() => history.replace("/app/my-products")}> <img src={LeftArrow} alt="" /> </div>
            <div className="other-item-wrap">
                <div className="heading-text">New Product</div>
                <div className="heading-msg">Kindly add a new product to your store by filling out the necessary information below</div>
            </div>
        </div>
    )
}

const InAppContent = ({ pageProps }: any) => {
    const screenResolution: any = useWindowSize();
    const [showImagesModal, setShowImagesModal] = useState(false);
    const [showCatError, setShowCatError] = useState(false);
    const [selectedImagesStore, setSelectedImagesStore] = useState<any[]>([]);
    const [selecteCatsStore, setSelecteCatsStore] = useState<any[]>([]);
    const [catListItems, setCatListItems] = useState<any>();

    const [allCatsList, setAllCatsList] = useState<any[]>([]);
    const [allCatsSelected, setAllCatsSelected] = useState<any[]>([]);
    const [startDate, onStartChange] = useState<any>();
    const [endDate, onEndChange] = useState<any>();
    const [isTaxableOption, setIsTaxable] = useState(false);
    const [isDiscountMore, setIsDiscountMore] = useState(false);
    // const otherData = 
    let imagesChosen: any = [];



    useEffect(() => {
        setShowCatError(false)
        setAllCatsSelected([])

        getMetadata({
            pageProps,
            // payload: "CLEAR"
        }).then(() => {


        })

        getImagesGallery({
            pageProps,
            payload: "CLEAR"
        })
        addNewProduct({
            pageProps,
            payload: "CLEAR"
        })


    }, [])


    useEffect(() => {


        if (pageProps.getProductMetaDataRequest.request_status === "GET_PRODUCT_META_SUCCESS") {
            uploadImagesData.length = 0;
            setSelectedImagesStore([])
            let catsData: any[] = pageProps.getProductMetaDataRequest.request_data.response.categories;

            let catFormated: any[] = catsData.map((each: any) => { return { label: each?.name, value: each?.name } })


            setAllCatsList(catFormated)


        }
    }, [pageProps?.getProductMetaDataRequest?.request_status])

    useEffect(() => {


        if (!showImagesModal) {
            setSelectedImagesStore(uploadImagesData)
        }
    }, [showImagesModal])

    const removeProductImage = (url: any) => {

        let imagesBatch = selectedImagesStore;

        imagesBatch = imagesBatch.filter((itemData: any) => {
            return itemData.url !== url;
        });
        uploadImagesData.length = 0;
        uploadImagesData.push(...imagesBatch)

        setSelectedImagesStore(imagesBatch)
    }

    const hideProductImgModal = () => setShowImagesModal(false);



    let detailsValidationSchema = Yup.object().shape({
        productName: Yup.string()
            .required("Required"),
        productPrice: Yup.string()
            .required("Required"),
        productSize: Yup.string()
            .required("Required"),
        productQty: Yup.string()
            .required("Required"),
        productUnit: Yup.string()
            .required("Required"),
        productTags: Yup.string(),
        addDiscount: Yup.boolean(),
        productDiscountPercent: Yup.string().when("addDiscount", {
            is: (addDiscount: boolean) => addDiscount === true,
            then: Yup.string().required('Required')
        }),
        productDiscountPrice: Yup.string().when("addDiscount", {
            is: (addDiscount: boolean) => addDiscount === true,
            then: Yup.string().required('Required')
        }),
        productDiscountStart: Yup.string().when("addDiscount", {
            is: (addDiscount: boolean) => addDiscount === true,
            then: Yup.string().required('Required')
        }),
        productDiscountEnd: Yup.string().when("addDiscount", {
            is: (addDiscount: boolean) => addDiscount === true,
            then: Yup.string().required('Required')
        }),
        // productDiscountPrice: Yup.string(),
        // productDiscountStart: Yup.string(),
        // productDiscountEnd: Yup.string(),
        productDesc: Yup.string()
            .required("Required"),
        // tax1: Yup.string()
        //     .required("Required"),
        // tax2: Yup.string()
        //     .required("Required"),
        // tax3: Yup.string()
        //     .required("Required"),

    });












    let initialValues: any = {
        productName: "",
        productPrice: "",
        productSize: "",
        productQty: "",
        productUnit: "",
        productStatus: "",
        productDesc: "",
        productDiscountPercent: "",
        productDiscountPrice: "",
        productDiscountStart: "",
        productDiscountEnd: "",
        startDate: "",
        endDate: "",
        addDiscount: false
    }



    return (
        <div className="inapp-content">
            <div className="page-content form-page">
                {/* {showImagesModal && <ProductImagePop />} */}
                {showImagesModal && <ProductImagePopUp
                    pageProps={pageProps}
                    showImagesModal={showImagesModal}
                    catListItems={catListItems}
                    hideProductImgModal={hideProductImgModal}
                />}
                 {(screenResolution?.width <= 767) &&
                <div className="mobile_back_nav">
                    <div className="left-nav" onClick={() => history.replace("/app/my-products")}> <img src={LeftArrow} alt="" /> </div>
                    <div className="back_nav_txt">
                        Back to Products
                    </div>
                </div>
                }

                {(pageProps.getProductMetaDataRequest.request_status === "GET_PRODUCT_META_SUCCESS" && allCatsList.length >= 1) &&
                    <div className="product-form">
                        {(screenResolution?.width <= 767) &&
                            <div className="other-item-wrap">
                                <div className="heading-text">New Product</div>
                                <div className="heading-msg">Kindly add a new product to your store by filling out the necessary information below</div>
                            </div>
                        }
                        <Formik
                            initialValues={initialValues}
                            validationSchema={detailsValidationSchema}
                            onSubmit={(values: any, actions) => {
                                let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
                                loggedAccountInfo = JSON.parse(loggedAccountInfo);

                                let urlsList = selectedImagesStore.map((img: any) => img.url);

                                let otherNamesList = selectedImagesStore.map((names: any) => {
                                    let nameList: any[];

                                    if (names?.othernames) {
                                        nameList = names.othernames.map((eachName: any) => eachName)
                                        return nameList.toString();
                                    }
                                    if (names?.otherNames) {
                                        nameList = names.otherNames.map((eachName: any) => eachName)
                                        return nameList.toString();
                                    }

                                });
                                otherNamesList = otherNamesList.filter((element: any, index: any) => {
                                    return otherNamesList.indexOf(element) === index;
                                });
                                otherNamesList = otherNamesList.filter(function (el: any) {
                                    return el != null && el != "" && el != undefined && el != 'undefined';
                                });




                                if (allCatsSelected.length === 0) {

                                    setShowCatError(true)
                                }
                                if (allCatsSelected.length >= 1 && urlsList.length >= 1) {
                                    setShowCatError(false)
                                    let chosenCats = allCatsSelected.map((cat: any) => cat.value);
                                    let formattedStart: any, formattedEnd: any;
                                    if (startDate && endDate) {
                                        formattedStart = new Date(startDate.toISOString());
                                        // formattedStart.setDate(formattedStart.getDate() + 1);
                                        formattedStart.setDate(formattedStart.getDate());
                                        formattedStart = formattedStart.toISOString();

                                        formattedEnd = new Date(endDate.toISOString());
                                        // formattedEnd.setDate(formattedEnd.getDate() + 1);
                                        formattedEnd.setDate(formattedEnd.getDate());
                                        formattedEnd = formattedEnd.toISOString();



                                    }

                                    let payload = {
                                        storeId: loggedAccountInfo.selectedStore.id,
                                        name: values.productName.trim(),
                                        categories: [...chosenCats],
                                        urls: [...urlsList],
                                        otherNames: [...otherNamesList],
                                        description: values.productDesc.trim(),
                                        sizePerUnit: parseFloat(values.productSize.replace(/,/g, '')),
                                        availableQuantity: parseInt(values.productQty),
                                        unitOfMeasureId: parseInt(values.productUnit),
                                        price: {
                                            amount: parseFloat(values.productPrice.replace(/,/g, '')),
                                            // isDiscount: false
                                        },

                                        // productDiscountPercent: !productInfo?.discountPrice ? "" : exisitingPerCentOffer,
                                        isTaxable: isTaxableOption,
                                        discountPrice: values.addDiscount ? {
                                            amount: parseFloat(values.productDiscountPrice.replace(/,/g, '')),
                                            startDate: formattedStart,
                                            endDate: formattedEnd
                                        } : null
                                    }







                                    addNewProduct({ pageProps, payload })
                                }
                            }}
                        >
                            {(props: FormikProps<any>) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    setFieldTouched,
                                    handleChange,
                                    setFieldValue,
                                } = props;
                                return (
                                    <Form>
                                        <div className="form-wrap">
                                            <div className="right-product-form">
                                                <div className="product-images-container">
                                                    <div className={(selectedImagesStore.length >= 1) ? "image-control" : "image-control full"}>
                                                        <div className="images-label">Product image</div>
                                                        <div className="product-images" onClick={() => {
                                                            let allCatData: any = [];
                                                            pageProps.getProductMetaDataRequest.request_data.response.categories.forEach((eachCat: any) => {
                                                                allCatData.push({
                                                                    value: eachCat.name,
                                                                    label: eachCat.name
                                                                })
                                                            })
                                                            imagesChosen.length = 1;
                                                            setCatListItems(allCatData)
                                                            let payload = `page=1&size=${sizeofFetch}`;
                                                            getImagesGallery({ pageProps, payload })

                                                            setShowImagesModal(true)
                                                        }}>
                                                            <div className="upload-wrap-content">
                                                                <img src={AddImg} alt="" />
                                                                <div className="upload-txt">Check <span>MamiMarkets Gallery</span>  or <span>Upload from your device</span></div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {
                                                        (selectedImagesStore.length >= 1) &&
                                                        // (!showImagesModal && selectedImagesStore.length >= 1) &&
                                                        <div className="all-images-chosen">
                                                            {
                                                                selectedImagesStore.map((eachImage: any, index: any) => {

                                                                    return (
                                                                        <div className="product-image-wrap" key={index}>
                                                                            <div className="image-actions">
                                                                                <div className="select-cover">
                                                                                    <input type="radio" name="cover-image-select" />
                                                                                </div>
                                                                                <div className="deleteicon" onClick={() => removeProductImage(eachImage.url)}>
                                                                                    <img src={TrashIcon} alt="" className="" />
                                                                                </div>
                                                                            </div>
                                                                            {/* <img src={eachImage.url} alt="" /> */}
                                                                            <div className="product-image-item"
                                                                                key={index}
                                                                            // style={{
                                                                            //     backgroundImage: `url(${eachImage.url})`
                                                                            // }}
                                                                            >
                                                                                <img src={eachImage.url} alt="" />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <div className="uploads-msg">
                                                    Ensure you use high-quality images that clearly showcase the product. Also note that pictures must be in 450 x 450 dimension. Max file size of 5mb.
                                                </div>
                                                {(screenResolution?.width >= 768) &&
                                                    <>
                                                        <div className="add-discount-wrap">
                                                            <Field onChange={handleChange} type="checkbox" name="addDiscount" id="addDiscount" />
                                                            <label htmlFor="addDiscount">Add a discount</label>
                                                        </div>
                                                        {values.addDiscount === true &&
                                                            <div className="discount-wrap">
                                                                <div className="two-sided">
                                                                    <div className="form-group">
                                                                        <div className={errors.productDiscountPercent && touched.productDiscountPercent ? "invalid-input-wrap " : ""}>
                                                                            <label htmlFor="productDiscountPercent">
                                                                                Discount %
                                                                                {values.productPrice &&
                                                                                    <ErrorMessage name="productDiscountPercent" className="form-input-error" component="span" />
                                                                                }
                                                                                {!values.productPrice &&
                                                                                    <span className="price-info-txt">(Enter original price)</span>
                                                                                }
                                                                            </label>



                                                                            <Field
                                                                                // value={values.productDiscountPercent}
                                                                                disabled={!values.productPrice}
                                                                                value={allowNumbersOnly(values.productDiscountPercent, 3)}
                                                                                name="productDiscountPercent"
                                                                                type="text"
                                                                                placeholder="Discount %"
                                                                                // onChange={handleChange}
                                                                                onChange={(e: any) => {

                                                                                    let valueSet: any = numberWithCommas(e.target.value)


                                                                                    if (parseFloat(valueSet) <= 100 || e.target.value === "") {
                                                                                        setFieldTouched("productDiscountPercent", true, true)
                                                                                        setFieldValue("productDiscountPercent", valueSet)

                                                                                        let originalPrice: any = parseFloat(values.productPrice.replace(/,/g, ''));
                                                                                        valueSet = valueSet.replace(/,/g, '');

                                                                                        let newProductPrice: any = parseFloat(valueSet) / 100 * parseFloat(originalPrice);
                                                                                        newProductPrice = parseFloat(originalPrice) - newProductPrice;
                                                                                        newProductPrice = Number.parseFloat(newProductPrice.toString()).toFixed(2);


                                                                                        setFieldValue("productDiscountPrice", newProductPrice.toString())




                                                                                    }



                                                                                }}
                                                                                className={`input-wrap inapp-input ${errors.productDiscountPercent && touched.productDiscountPercent ?
                                                                                    "is-invalid-input "
                                                                                    : !errors.productDiscountPercent && values.productDiscountPercent !== "" ? "valid-input" : ""}`}
                                                                            >

                                                                            </Field>

                                                                        </div>

                                                                    </div>
                                                                    <div className="form-group">
                                                                        <div className={errors.productDiscountPrice && touched.productDiscountPrice ? "invalid-input-wrap " : ""}>
                                                                            <label htmlFor="productDiscountPrice">
                                                                                New price
                                                                                {values.productPrice &&
                                                                                    <ErrorMessage name="productDiscountPrice" className="form-input-error" component="span" />
                                                                                }

                                                                                {!values.productPrice &&
                                                                                    <span className="price-info-txt">(Enter original price)</span>
                                                                                }
                                                                            </label>

                                                                            <div className="price-wrap">
                                                                                <span className="currency-pre">$</span>
                                                                                <Field
                                                                                    value={numberWithCommas(values.productDiscountPrice)}
                                                                                    name="productDiscountPrice"
                                                                                    type="text"
                                                                                    disabled={!values.productPrice}
                                                                                    onChange={(e: any) => {

                                                                                        let valueSet: any = numberWithCommas(e.target.value)
                                                                                        setFieldTouched("productDiscountPrice", true, true)


                                                                                        setFieldValue("productDiscountPrice", valueSet)

                                                                                        if (parseFloat(valueSet) >= parseFloat(values.productPrice)) {
                                                                                            setIsDiscountMore(true)
                                                                                            setFieldValue("productDiscountPercent", "")

                                                                                        } else {
                                                                                            setIsDiscountMore(false)
                                                                                            let originalPrice: any = parseFloat(values.productPrice.replace(/,/g, ''));
                                                                                            valueSet = valueSet.replace(/,/g, '');

                                                                                            let diff = parseFloat(originalPrice) - parseFloat(valueSet),
                                                                                                perCentOffer = Math.round((diff / parseFloat(originalPrice)) * 100);

                                                                                            setFieldValue("productDiscountPercent", perCentOffer.toString())


                                                                                        }
                                                                                        // setFieldError



                                                                                    }}
                                                                                    // onChange={handleChange}
                                                                                    className={`input-wrap inapp-input ${errors.productDiscountPrice && touched.productDiscountPrice ?
                                                                                        "is-invalid-input "
                                                                                        : !errors.productDiscountPrice && values.productDiscountPrice !== "" ? "valid-input" : ""}`}
                                                                                >

                                                                                </Field>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="two-sided">
                                                                    <div className="form-group">
                                                                        <div className={errors.productDiscountStart && touched.productDiscountStart ? "invalid-input-wrap " : ""}>
                                                                            <label htmlFor="productDiscountStart">
                                                                                Start date
                                                                                <ErrorMessage name="productDiscountStart" className="form-input-error" component="span" />
                                                                            </label>

                                                                            <DatePicker
                                                                                selected={startDate}
                                                                                onChange={(date: any) => {
                                                                                    onStartChange(date)
                                                                                    setFieldTouched("productDiscountStart", true)
                                                                                    setFieldValue("productDiscountStart", date.toISOString())
                                                                                }}
                                                                                minDate={new Date()}
                                                                                name="productDiscountStart"
                                                                                // maxDate={new Date()}
                                                                                placeholderText="Select start date"
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                    <div className="form-group">
                                                                        <div className={errors.productDiscountEnd && touched.productDiscountEnd ? "invalid-input-wrap " : ""}>
                                                                            <label htmlFor="productDiscountEnd">
                                                                                End date
                                                                                <ErrorMessage name="productDiscountEnd" className="form-input-error" component="span" />
                                                                            </label>
                                                                            <DatePicker
                                                                                selected={endDate}
                                                                                onChange={(date: any) => {
                                                                                    onEndChange(date)
                                                                                    setFieldTouched("productDiscountEnd", true)
                                                                                    setFieldValue("productDiscountEnd", date.toISOString())
                                                                                }}
                                                                                name="productDiscountEnd"

                                                                                minDate={new Date()}
                                                                                // maxDate={new Date()}
                                                                                placeholderText="Select end date"
                                                                            />

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="product-cta">
                                                            <button
                                                                // disabled={pageProps.createStoreProductRequest?.is_request_processing || selecteCatsStore.length < 1 || selectedImagesStore.length < 1}
                                                                disabled={pageProps.createStoreProductRequest?.is_request_processing || allCatsSelected.length < 1 || selectedImagesStore.length < 1}
                                                                className={pageProps.createStoreProductRequest?.is_request_processing || allCatsSelected.length < 1 || selectedImagesStore.length < 1 ? "btn btn-disabled" : "btn"}>
                                                                {/* className={pageProps.createStoreProductRequest?.is_request_processing || selecteCatsStore.length < 1 || selectedImagesStore.length < 1 ? "btn btn-disabled" : "btn"}> */}
                                                                {pageProps.createStoreProductRequest.is_request_processing ? "Please wait..." : "Add Product"}
                                                                {/* {` imgs ${selectedImagesStore.length}`}
                                                {` cats ${selecteCatsStore.length}`} */}
                                                            </button>
                                                        </div>
                                                    </>
                                                }
                                                {pageProps?.createStoreProductRequest?.request_status
                                                    && pageProps.createStoreProductRequest.request_status === "CREATE_PRODUCT_FAILURE" &&
                                                    <AlertMsg type="error" message={pageProps.createStoreProductRequest?.request_data.error} />
                                                }
                                                {showCatError &&
                                                    <AlertMsg type="error" message="Select product category" />
                                                }
                                            </div>
                                            <div className="product-info">
                                                <div className="form-group">
                                                    <div className={errors.productName && touched.productName ? "invalid-input-wrap" : ""}>
                                                        <label htmlFor="productName">
                                                            Product name
                                                            <ErrorMessage name="productName" className="form-input-error" component="span" />
                                                        </label>
                                                        <Field
                                                            value={values.productName}
                                                            name="productName"
                                                            id="productName"
                                                            autoComplete="off"
                                                            type="text"
                                                            placeholder="Enter product name here"
                                                            onChange={handleChange}
                                                            className={`input-wrap inapp-input ${errors.productName && touched.productName ?
                                                                "is-invalid-input "
                                                                : !errors.productName && values.productName !== "" ? "valid-input" : ""}`}
                                                        >

                                                        </Field>
                                                        <small className="help-text">Maximum of 50 characters</small>
                                                    </div>

                                                </div>
                                                <div className="form-group">
                                                    <div className="">
                                                        {/* <div className={errors.productName && touched.productName ? "invalid-input-wrap" : ""}> */}
                                                        <label htmlFor="productName">Category</label>
                                                        <MultiSelect
                                                            options={allCatsList}
                                                            value={allCatsSelected}
                                                            onChange={setAllCatsSelected}
                                                            labelledBy="Select"
                                                            hasSelectAll={false}
                                                            className="cat-select-wrap"
                                                        // isLoading={true}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="two-sided for_pricing">
                                                    <div className="form-group pricing_wrap">
                                                        <div className={errors.productPrice && touched.productPrice ? "invalid-input-wrap " : ""}>
                                                            <label htmlFor="productPrice">
                                                                Price
                                                                <ErrorMessage name="productPrice" className="form-input-error" component="span" />
                                                            </label>

                                                            <div className="price-wrap">
                                                                <span className="currency-pre">$</span>
                                                                <Field
                                                                    value={numberWithCommas(values.productPrice)}
                                                                    name="productPrice"
                                                                    type="text"
                                                                    placeholder=" Price"
                                                                    autoComplete="off"
                                                                    // onChange={handleChange}
                                                                    onChange={(e: any) => {
                                                                        let valueSet: any = numberWithCommas(e.target.value)
                                                                        setFieldTouched("productPrice", true, true)


                                                                        setFieldValue("productPrice", valueSet)
                                                                        if (!e.target.value) {

                                                                            setFieldValue("productDiscountPercent", "")
                                                                            setFieldValue("productDiscountPrice", "")

                                                                        } else {

                                                                            if (values.productDiscountPercent) {
                                                                                let disCountPercentvalue: any = values.productDiscountPercent.replace(/,/g, ''),
                                                                                    originalPrice: any = parseFloat(valueSet.replace(/,/g, ''));


                                                                                if (parseFloat(disCountPercentvalue) <= 100) {



                                                                                    let newProductPrice: any = parseFloat(disCountPercentvalue) / 100 * originalPrice;
                                                                                    newProductPrice = originalPrice - newProductPrice;
                                                                                    newProductPrice = Number.parseFloat(newProductPrice.toString()).toFixed(2);


                                                                                    setFieldValue("productDiscountPrice", newProductPrice)

                                                                                }

                                                                            }


                                                                        }
                                                                    }}
                                                                    className={`input-wrap inapp-input ${errors.productPrice && touched.productPrice ?
                                                                        "is-invalid-input "
                                                                        : !errors.productPrice && values.productPrice !== "" ? "valid-input" : ""}`}
                                                                >

                                                                </Field>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="form-group qty_only_wrap">
                                                        <label htmlFor="productSize">
                                                            Size
                                                            <ErrorMessage name="productSize" className="form-input-error" component="span" />
                                                        </label>
                                                        <div className={((errors.productSize && touched.productSize) || (errors.productUnit)
                                                        ) ? "invalid-input qty-input" : "qty-input"}>
                                                            <div className="input-item">

                                                                <Field
                                                                    value={numberWithCommas(values.productSize)}
                                                                    name="productSize"
                                                                    type="text"
                                                                    autoComplete="off"
                                                                    placeholder="Size"
                                                                    onChange={handleChange}
                                                                    className={` ${errors.productSize && touched.productSize ?
                                                                        "is-invalid-input "
                                                                        : !errors.productSize && values.productSize !== "" ? "valid-input" : ""}`}
                                                                >

                                                                </Field>
                                                            </div>
                                                            <Field
                                                                value={values.productUnit}
                                                                name="productUnit"
                                                                as="select"
                                                                autoComplete="off"
                                                                onChange={handleChange}
                                                                placeholder=""
                                                                className={`${errors.productUnit && touched.productUnit ?
                                                                    "is-invalid-input"
                                                                    : !errors.productUnit && values.productUnit !== "" ? "valid-input" : ""}`}
                                                            >
                                                                <option value="">size</option>
                                                                {
                                                                    pageProps.getProductMetaDataRequest.request_data.response.units.map((eachUnit: any, index: any) => {

                                                                        return (
                                                                            <option key={index} value={eachUnit.id}>{eachUnit.unit}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </Field>
                                                        </div>

                                                    </div>
                                                </div>


                                                {/* <div className="panel-input">
                                                    <div className="input-title">
                                                        <span>Choose Category</span>
                                                        <img src={CaretDn} alt="" />
                                                    </div>
                                                    <div className="category-lists ">
                                                        {
                                                            pageProps.getProductMetaDataRequest.request_data.response.categories.map((catItem: any, index: any) => {
                                                                return (
                                                                    <div className="each-cat" key={index}>
                                                                        
                                                                        <label className="checkbox-wrap" htmlFor={`${catItem.name}-${catItem.id}`}>
                                                                            <input type="checkbox" id={`${catItem.name}-${catItem.id}`}
                                                                                onChange={(event) => {
                                                                                    let catsTemp = [...selecteCatsStore]


                                                                                    if (catsTemp.indexOf(catItem.name) > -1) {


                                                                                        catsTemp.splice(catsTemp.indexOf(catItem.name), 1)


                                                                                        setSelecteCatsStore(catsTemp)
                                                                                    } else {

                                                                                        catsTemp.push(catItem.name);

                                                                                        setSelecteCatsStore(catsTemp)

                                                                                        setShowCatError(false)
                                                                                    }

                                                                                }}
                                                                            />
                                                                            <span className="checkbox_checkmark"></span>
                                                                        </label>
                                                                        <label htmlFor={`${catItem.name}-${catItem.id}`} >{catItem.name}</label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div> */}
                                                <div className="form-group">
                                                    <label htmlFor="productQty">
                                                        Quantity Available
                                                        <ErrorMessage name="productQty" className="form-input-error" component="span" />
                                                    </label>
                                                    <div className={errors.productQty && touched.productQty ? "invalid-input-wrap" : ""}>
                                                        <Field
                                                            value={allowNumbersOnly(values.productQty)}
                                                            name="productQty"
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder="Enter Quantity"
                                                            onChange={handleChange}
                                                            className={`input-wrap inapp-input ${errors.productQty && touched.productQty ?
                                                                "is-invalid-input "
                                                                : !errors.productQty && values.productQty !== "" ? "valid-input" : ""}`}
                                                        >

                                                        </Field>
                                                    </div>

                                                </div>

                                                <div className="form-group">
                                                    <div>
                                                        <label htmlFor="productDesc">
                                                            Description
                                                            <ErrorMessage name="productDesc" className="form-input-error" component="span" />
                                                        </label>
                                                        <Field
                                                            value={values.productDesc}
                                                            name="productDesc"
                                                            as="textarea"
                                                            autoComplete="off"
                                                            onChange={handleChange}
                                                            className={`input-wrap inapp-input ${errors.productDesc && touched.productDesc ?
                                                                "is-invalid-input "
                                                                : !errors.productDesc && values.productDesc !== "" ? "valid-input" : ""}`}
                                                        >

                                                        </Field>
                                                        <small className="help-text">Maximum of 500 characters</small>
                                                    </div>

                                                </div>
                                                <div className="form-group">
                                                    <div className="tax-label">Is this product taxable? <span>Learn more</span> </div>
                                                    <div className="all_options">
                                                        <div className="an_option">
                                                            <span className={` custom_radio  ${isTaxableOption ? "selected_option" : ""}`}>
                                                                <input onChange={(e) => setIsTaxable(true)} name="check_taxable" type="radio" id="isTaxable" value="isTaxable" />
                                                            </span>
                                                            <label htmlFor="isTaxable">
                                                                <span>Yes</span>

                                                            </label>
                                                        </div>
                                                        <div className="an_option">
                                                            <span className={`custom_radio ${!isTaxableOption ? "selected_option" : ""}`}>
                                                                <input onChange={(e) => setIsTaxable(false)} name="check_taxable" type="radio" id="notTaxable" value="notTaxable" />
                                                            </span>
                                                            <label htmlFor="notTaxable">
                                                                <span>No</span>

                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className={errors.productTags && touched.productTags ? "invalid-input-wrap" : ""}>
                                                        <label htmlFor="productTags">
                                                            Product tags (optional)
                                                            <ErrorMessage name="productTags" className="form-input-error" component="span" />
                                                        </label>
                                                        <Field
                                                            value={values.productTags}
                                                            name="productTags"
                                                            id="productTags"
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder="Enter product name here"
                                                            onChange={handleChange}
                                                            className={`input-wrap inapp-input ${errors.productTags && touched.productTags ?
                                                                "is-invalid-input "
                                                                : !errors.productTags && values.productTags !== "" ? "valid-input" : ""}`}
                                                        >

                                                        </Field>
                                                        {/* <small className="help-text">Maximum of 50 characters</small> */}
                                                    </div>

                                                </div>
                                                {(screenResolution?.width <= 767) &&
                                                    <>
                                                        <div className="add-discount-wrap">
                                                            <Field onChange={handleChange} type="checkbox" name="addDiscount" id="addDiscount" />
                                                            <label htmlFor="addDiscount">Add a discount</label>
                                                        </div>
                                                        {values.addDiscount === true &&
                                                            <div className="discount-wrap">
                                                                <div className="two-sided">
                                                                    <div className="form-group">
                                                                        <div className={errors.productDiscountPercent && touched.productDiscountPercent ? "invalid-input-wrap " : ""}>
                                                                            <label htmlFor="productDiscountPercent">
                                                                                Discount %
                                                                                {values.productPrice &&
                                                                                    <ErrorMessage name="productDiscountPercent" className="form-input-error" component="span" />
                                                                                }
                                                                                {/* {!values.productPrice &&
                                                                                    <span className="price-info-txt">(Enter original price)</span>
                                                                                } */}
                                                                            </label>



                                                                            <Field
                                                                                // value={values.productDiscountPercent}
                                                                                disabled={!values.productPrice}
                                                                                value={allowNumbersOnly(values.productDiscountPercent, 3)}
                                                                                name="productDiscountPercent"
                                                                                type="text"
                                                                                placeholder="Discount %"
                                                                                // onChange={handleChange}
                                                                                onChange={(e: any) => {

                                                                                    let valueSet: any = numberWithCommas(e.target.value)


                                                                                    if (parseFloat(valueSet) <= 100 || e.target.value === "") {
                                                                                        setFieldTouched("productDiscountPercent", true, true)
                                                                                        setFieldValue("productDiscountPercent", valueSet)

                                                                                        let originalPrice: any = parseFloat(values.productPrice.replace(/,/g, ''));
                                                                                        valueSet = valueSet.replace(/,/g, '');

                                                                                        let newProductPrice: any = parseFloat(valueSet) / 100 * parseFloat(originalPrice);
                                                                                        newProductPrice = parseFloat(originalPrice) - newProductPrice;
                                                                                        newProductPrice = Number.parseFloat(newProductPrice.toString()).toFixed(2);


                                                                                        setFieldValue("productDiscountPrice", newProductPrice.toString())




                                                                                    }



                                                                                }}
                                                                                className={`input-wrap inapp-input ${errors.productDiscountPercent && touched.productDiscountPercent ?
                                                                                    "is-invalid-input "
                                                                                    : !errors.productDiscountPercent && values.productDiscountPercent !== "" ? "valid-input" : ""}`}
                                                                            >

                                                                            </Field>

                                                                        </div>

                                                                    </div>
                                                                    <div className="form-group">
                                                                        <div className={errors.productDiscountPrice && touched.productDiscountPrice ? "invalid-input-wrap " : ""}>
                                                                            <label htmlFor="productDiscountPrice">
                                                                                New price
                                                                                {values.productPrice &&
                                                                                    <ErrorMessage name="productDiscountPrice" className="form-input-error" component="span" />
                                                                                }

                                                                                {/* {!values.productPrice &&
                                                                                    <span className="price-info-txt">(Enter original price)</span>
                                                                                } */}
                                                                            </label>

                                                                            <div className="price-wrap">
                                                                                <span className="currency-pre">$</span>
                                                                                <Field
                                                                                    value={numberWithCommas(values.productDiscountPrice)}
                                                                                    name="productDiscountPrice"
                                                                                    type="text"
                                                                                    disabled={!values.productPrice}
                                                                                    onChange={(e: any) => {

                                                                                        let valueSet: any = numberWithCommas(e.target.value)
                                                                                        setFieldTouched("productDiscountPrice", true, true)


                                                                                        setFieldValue("productDiscountPrice", valueSet)

                                                                                        if (parseFloat(valueSet) >= parseFloat(values.productPrice)) {
                                                                                            setIsDiscountMore(true)
                                                                                            setFieldValue("productDiscountPercent", "")

                                                                                        } else {
                                                                                            setIsDiscountMore(false)
                                                                                            let originalPrice: any = parseFloat(values.productPrice.replace(/,/g, ''));
                                                                                            valueSet = valueSet.replace(/,/g, '');

                                                                                            let diff = parseFloat(originalPrice) - parseFloat(valueSet),
                                                                                                perCentOffer = Math.round((diff / parseFloat(originalPrice)) * 100);

                                                                                            setFieldValue("productDiscountPercent", perCentOffer.toString())


                                                                                        }
                                                                                        // setFieldError



                                                                                    }}
                                                                                    // onChange={handleChange}
                                                                                    className={`input-wrap inapp-input ${errors.productDiscountPrice && touched.productDiscountPrice ?
                                                                                        "is-invalid-input "
                                                                                        : !errors.productDiscountPrice && values.productDiscountPrice !== "" ? "valid-input" : ""}`}
                                                                                >

                                                                                </Field>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="two-sided">
                                                                    <div className="form-group">
                                                                        <div className={errors.productDiscountStart && touched.productDiscountStart ? "invalid-input-wrap " : ""}>
                                                                            <label htmlFor="productDiscountStart">
                                                                                Start date
                                                                                <ErrorMessage name="productDiscountStart" className="form-input-error" component="span" />
                                                                            </label>

                                                                            <DatePicker
                                                                                selected={startDate}
                                                                                showIcon={true}
                                                                                onChange={(date: any) => {
                                                                                    onStartChange(date)
                                                                                    setFieldTouched("productDiscountStart", true)
                                                                                    setFieldValue("productDiscountStart", date.toISOString())
                                                                                }}
                                                                                minDate={new Date()}
                                                                                name="productDiscountStart"
                                                                                // maxDate={new Date()}
                                                                                placeholderText="Select start date"
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                    <div className="form-group">
                                                                        <div className={errors.productDiscountEnd && touched.productDiscountEnd ? "invalid-input-wrap " : ""}>
                                                                            <label htmlFor="productDiscountEnd">
                                                                                End date
                                                                                <ErrorMessage name="productDiscountEnd" className="form-input-error" component="span" />
                                                                            </label>
                                                                            <DatePicker
                                                                                showIcon={true}
                                                                                selected={endDate}
                                                                                onChange={(date: any) => {
                                                                                    onEndChange(date)
                                                                                    setFieldTouched("productDiscountEnd", true)
                                                                                    setFieldValue("productDiscountEnd", date.toISOString())
                                                                                }}
                                                                                name="productDiscountEnd"

                                                                                minDate={new Date()}
                                                                                // maxDate={new Date()}
                                                                                placeholderText="Select end date"
                                                                            />

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="product-cta">
                                                            <button
                                                                // disabled={pageProps.createStoreProductRequest?.is_request_processing || selecteCatsStore.length < 1 || selectedImagesStore.length < 1}
                                                                disabled={pageProps.createStoreProductRequest?.is_request_processing || allCatsSelected.length < 1 || selectedImagesStore.length < 1}
                                                                className={pageProps.createStoreProductRequest?.is_request_processing || allCatsSelected.length < 1 || selectedImagesStore.length < 1 ? "btn btn-disabled" : "btn"}>
                                                                {/* className={pageProps.createStoreProductRequest?.is_request_processing || selecteCatsStore.length < 1 || selectedImagesStore.length < 1 ? "btn btn-disabled" : "btn"}> */}
                                                                {pageProps.createStoreProductRequest.is_request_processing ? "Please wait..." : "Add Product"}
                                                                {/* {` imgs ${selectedImagesStore.length}`}
                                                {` cats ${selecteCatsStore.length}`} */}
                                                            </button>
                                                        </div>
                                                    </>
                                                }

                                                {/* {pageProps?.createStoreProductRequest?.request_status
                                                    && pageProps.createStoreProductRequest.request_status === "CREATE_PRODUCT_FAILURE" &&
                                                    <AlertMsg type="error" message={pageProps.createStoreProductRequest?.request_data.error} />
                                                }
                                                {showCatError &&
                                                    <AlertMsg type="error" message="Select product category" />
                                                } */}
                                                {/* {selecteCatsStore.map((item: any) => {
                                                    return <pre>{item}</pre>;
                                                })} */}
                                            </div>



                                        </div>

                                    </Form>
                                )
                            }}

                        </Formik>
                    </div>
                }
                {pageProps.getProductMetaDataRequest.request_status === "GET_PRODUCT_META_PENDING" &&
                    <LoadingItem />
                }
            </div>
        </div>
    )
}
const AddNewProduct = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Add New Product" showMainPageHeading={true} mainPageHeading={<MainHeader />} childComponent={<InAppContent pageProps={pageProps} />} />
    )
}

const mapDispatchToProps = {
    createProductAction: storeActions.CreateStoreProduct,
    getMetadataAction: storeActions.GetProductMetaData,
    getImageGalleryAction: storeActions.GetImageGallery,
    uploadProductImageAction: storeActions.UploadProductImage,

};

const mapStateToProps = (state: any) => ({
    // return {
    // allImagesGallery: ()=>{
    //     return 
    // },
    getProductMetaDataRequest: state.allProductReducers.getProductMetaDataReducer,
    getImageGalleryRequest: state.allProductReducers.getImageGalleryReducer,
    createStoreProductRequest: state.allProductReducers.createStoreProductReducer,
    updateProductImageRequest: state.allProductReducers.updateProductImageReducer,

    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(AddNewProduct);