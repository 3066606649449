import { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import Dropzone, { IDropzoneProps, ILayoutProps } from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Image, Shimmer } from 'react-shimmer'
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import { WithContext as ReactTags } from 'react-tag-input';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup';
import Form2 from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { InAppTemplate } from "../../../shared/templates/inapp";
import { allowNumbersOnly, numberWithoutDecimals, numberWithCommas } from "../../../shared/utils"
import CaretDn from "../../../assets/caret-dn.svg"
import AddImg from "../../../assets/add-img.svg"
import UploadIcon from "../../../assets/icons/upload.svg"
import TrashIcon from "../../../assets/icons/ic_delete.svg"
import DoneIcon from "../../../assets/icons/done.svg"
import RemoveIcon from "../../../assets/icons/remove.svg"
import SearchIco from "../../../assets/icons/search.svg"
import { history } from "../../../shared/_helpers/history";

import "../index.scss"
import { AlertMsg } from "../../../shared/components/alert-msg"
import { LoadingItem } from "../../../shared/components/loading"
import { storeActions } from "../../../redux/actions/in-app"
import LeftArrow from "../../../assets/icons/left-arrow.png"

import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";



const uploadImagesData: any[] = [];
const sizeofFetch = 8;


const getProductDetail = async ({ pageProps, payload }: any) => {

    await pageProps.getAProduct(payload);
}

const getMetadata = async ({ pageProps, payload }: any) => {

    await pageProps.getMetadataAction(payload);
}

const addNewProduct = async ({ pageProps, payload }: any) => {

    await pageProps.updateProductAction(payload);
}

const editProduct = async ({ pageProps, payload }: any) => {

    await pageProps.updateProductAction(payload);
}

const uploadProductImage = async ({ pageProps, payload, otherNames }: any) => {

    await pageProps.uploadProductImageAction(payload, otherNames);
}

const getImagesGallery = async ({ pageProps, payload }: any) => {

    await pageProps.getImageGalleryAction(payload, false);
}

const getMoreImages = async ({ pageProps, payload }: any) => {

    await pageProps.getImageGalleryAction(payload, true);
}

const ProductImagePopUp = ({ pageProps, showImagesModal, catListItems, hideProductImgModal, tempImages }: any) => {

    const [categoryChosen, setCategoryChosen] = useState<any>();
    const [searchTerm, setSearchTerm] = useState("");
    const [categoryToUpload, setUploadCategory] = useState<any>();
    const [productUploadName, setUploadProductName] = useState("");
    const [productNameError, setProductNameError] = useState(true);
    const [productCatError, setProductCatError] = useState(true);
    const [setImagesUploaded, setImagesUploadedStore] = useState<any>([...tempImages]);
    const [imageData, setImageUploadData] = useState<any>();
    const [hasImageUploaded, setHasImageUploaded] = useState(false);
    const productUploadNameRef = useRef<any>(null);
    const [productUploadOtherNames, setUploadProductOtherNames] = useState<any>([]);
    const [uploadOtherNamesTags, setUploadOtherNamesTags] = useState<any>([]);
    const [showUploadAnother, setShowUploadAnother] = useState(false);
    let imagesChosen: any = [];

    useEffect(() => {

        if (imageData) {
            const imageUploadData = new FormData();
            imageUploadData.append("ProductName", imageData.ProductName);
            imageUploadData.append("CategoryName", imageData.CategoryName);
            imageUploadData.append("ImageFile", imageData.ImageFile);
            imageUploadData.append("OtherNames", imageData.OtherNames);


            uploadProductImage({ pageProps, payload: imageUploadData, otherNames: imageData.OtherNames })
                .then(() => {

                    setHasImageUploaded(true)

                })

        }


    }, [imageData])


    useEffect(() => {
        if (hasImageUploaded && pageProps.updateProductImageRequest.request_status === "UPLOAD_PRODUCT_PICTURE_SUCCESS") {

            let imageLink = pageProps.updateProductImageRequest.request_data;
            imagesChosen = [...setImagesUploaded]
            imagesChosen.push({
                index: setImagesUploaded.length + 1,
                url: imageLink.response.fileURL,
                otherNames: imageLink?.response?.otherNames ? [...imageLink?.response?.otherNames] : []
                // url: imageLink.response.thumbnail
            })

            let imagesList = [...setImagesUploaded]
            imagesList.push({
                index: setImagesUploaded.length + 1,
                url: imageLink.response.fileURL,
                otherNames: imageLink?.response?.otherNames ? [...imageLink?.response?.otherNames] : []
                // url: imageLink.response.thumbnail
            })
            setImagesUploadedStore(imagesList)


            setHasImageUploaded(false)
        }
    }, [hasImageUploaded])

    const transformImageName = (image: any) => {
        let filename = image;

        if (filename.indexOf("upload-file/") > -1) {
            filename = image.split("upload-file/")[1];
        }
        if (filename.indexOf("thumbnails/") > -1) {
            filename = image.split("thumbnails/")[1];
        }


        return filename;
    }
    const removeImageFromUploads = (chosenImg: any) => {
        let imagesList = [...setImagesUploaded];
        imagesList = imagesList.filter((itemData: any) => {
            return itemData.url !== chosenImg.url
        });
        setImagesUploadedStore(imagesList)
    }

    const saveSearchFilter = (searchTerm: any) => {
        setCategoryChosen({
            label: null,
            value: null
        })
        let payload = `page=1&size=${sizeofFetch}${searchTerm ? `&fileName=${searchTerm}` : ""}`;
        getImagesGallery({ pageProps, payload })
    }

    const saveChosenCat = (chosenCat: any) => {
        setSearchTerm("")
        setCategoryChosen(chosenCat)
        let payload = `page=1&size=${sizeofFetch}${chosenCat?.label ? `&fileName=${chosenCat.label}` : ""}`;
        getImagesGallery({ pageProps, payload })
    }

    const saveUploadCat = (chosenCat: any) => {
        setProductCatError(false)
        setUploadCategory(chosenCat)
    }

    const handleDelete = (i: any) => {
        // this.setState({
        //     tags: this.state.tags.filter((tag, index) => index !== i),
        // });
        setUploadOtherNamesTags(uploadOtherNamesTags.filter((tag: any, index: any) => index !== i))
    }

    const handleAddition = (tag: any) => {

        if (productUploadOtherNames.indexOf(tag?.text) === -1) {
            setUploadOtherNamesTags([...uploadOtherNamesTags, tag])
            setUploadProductOtherNames([...productUploadOtherNames, tag?.text])
        }
        // this.setState({ tags: [...tags, { id: tags.length + 1, text: tag }] });
    }

    const KeyCodes = {
        comma: 188,
        enter: 13
    };
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const ImageUploadBox = () => {


        const getUploadParams: IDropzoneProps['getUploadParams'] = () => {
            return { url: 'https://httpbin.org/post' }
            // return { url: 'https://httpbin.org/post' }
        }

        const handleChangeStatus: IDropzoneProps['onChangeStatus'] = ({ meta }, status) => {

        }


        const handleSubmit: IDropzoneProps['onSubmit'] = (files: any, allFiles) => {

            // if (productUploadName) {
            //     setProductNameError(false)
            // } else {
            //     setProductNameError(true)
            // }

            // if (categoryToUpload) {
            //     setProductCatError(false)
            // } else {
            //     setProductCatError(true)
            // }

            if (!productNameError && !productCatError) {
                // setProductNameError(true)

                let imageData = {
                    ProductName: productUploadNameRef?.current?.value,
                    CategoryName: categoryToUpload.value,
                    ImageFile: files[0].file,
                    OtherNames: productUploadOtherNames
                }
                setUploadProductName("")
                setImageUploadData(imageData)


            } else {
                setProductNameError(true)
            }

            // allFiles.forEach((f) => f.remove())
        }

        return (
            <div className={!productUploadNameRef?.current?.value || productCatError ? "upload_img_wrap disabled_wrap" : "upload_img_wrap"}>
                <Dropzone
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    accept="image/*"
                    maxFiles={1}
                    // disabled={productNameError && productCatError}
                    multiple={false}
                    canRestart={false}
                    canCancel={false}
                    // autoUpload={false}
                    submitButtonContent="Upload image"
                    inputContent={() => {
                        return (<div className="upload_cnt upload_msg" key={30}>
                            <img src={UploadIcon} alt="" />
                            {!showUploadAnother && <div className="upload-txt">Upload photo here</div>}
                            {showUploadAnother && <div className="upload-txt">Upload another photo here</div>}
                            <div className="or-txt">or</div>
                            <div className="upload-cta">Browse files</div>
                        </div>)
                    }}
                    styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
                />
            </div>
        )
    }

    return (
        <Modal className="upload_images_wrap" show={showImagesModal} onHide={hideProductImgModal}>
            <Modal.Header closeButton>
                <div className="">Upload product image </div>
            </Modal.Header>
            <Modal.Body>
                <Tab.Container defaultActiveKey="mami_gallery">
                    <Nav className="other_productinfo_tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="upload_now">Upload Image</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="mami_gallery">MamiMarket Gallery</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className="other_productinfo_tabs_content">
                        <Tab.Pane eventKey="upload_now">
                            <div>
                                <div className="product-info image-info-details">
                                    <div className="two-sided">
                                        <div className="form-group">
                                            <input placeholder="Product name"
                                                onChange={(e: any) => {


                                                    // setUploadProductName(e.target.value)
                                                }}
                                                onBlur={(e: any) => {
                                                    if (productUploadNameRef?.current?.value.length < 1) {
                                                        // if (e.target.value.trim().length < 1) {
                                                        setProductNameError(true)
                                                    } else {
                                                        setProductNameError(false)
                                                    }
                                                }}
                                                type="text"
                                                // value={productUploadName}
                                                ref={productUploadNameRef}
                                                name="" id=""
                                                className="input-wrap" />
                                            {/* {productNameError &&
                                                <div className="form-input-error" >Required</div>
                                            } */}
                                        </div>

                                        <div className="form-group select-cat-wrap">
                                            <Select
                                                value={categoryToUpload}
                                                onChange={saveUploadCat}
                                                options={catListItems}
                                                placeholder="Category"
                                                className="select-wrap"
                                            />
                                            {/* <select name="" id="">
                                                <option value="">Choose a category</option>
                                                {
                                                    pageProps.getProductMetaDataRequest.request_data.response.categories.map((catItem: any, index: any) => {
                                                        return (
                                                            <option key={index}>{catItem.name}</option>
                                                        )
                                                    })
                                                }
                                            </select> */}
                                            {/* {productCatError &&
                                                <div className="form-input-error">Required</div>
                                            } */}

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <ReactTags
                                            delimiters={delimiters}
                                            tags={uploadOtherNamesTags}
                                            handleDelete={handleDelete}
                                            handleAddition={handleAddition}
                                            classNames={{
                                                tagInput: 'tagInputClass',
                                                tagInputField: 'input-wrap'
                                            }}
                                            placeholder="Other Names of this upload. e.g: Name1, Name2, Name3"
                                        />
                                        {/* <input placeholder="Other Names of this upload. e.g: Name1, Name2, Name3"
                                            onChange={(e: any) => {
                                                if (e.target.value.trim().length >= 1) {
                                                    setUploadProductOtherNames(e.target.value)
                                                }


                                            }}
                                            type="text"
                                            value={productUploadOtherNames}
                                            name="" id=""
                                            className="input-wrap" /> */}
                                        <span className="info-txt">**Type Other Name and press enter</span>

                                    </div>
                                </div>
                                {(productNameError || productCatError) &&
                                    <div className="form-input-error text-center">Provide product image name and category before upload</div>
                                }
                                <ImageUploadBox />


                                {pageProps.updateProductImageRequest.request_status === "UPLOAD_PRODUCT_PICTURE_SUCCESS" &&
                                    <>

                                        {setImagesUploaded.map((eachImage: any, index: any) => (
                                            <div className="progress-indicator" key={index}>
                                                <div className="uploading">
                                                    <div className="file-progress-wrap">
                                                        <div className="filename">{transformImageName(eachImage.url)}</div>
                                                    </div>
                                                    <div className="status-icons">
                                                        <div className="remove-now"
                                                            onClick={() => {
                                                                removeImageFromUploads(eachImage)
                                                            }}
                                                        ><img src={RemoveIcon} alt="" />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        )
                                        }
                                    </>
                                }
                            </div>
                            {/* <div className="progress-indicator">
                                <div className="uploading">
                                    <div className="file-progress-wrap">
                                        <div className="filename">Tungchivo-avatar.png</div>
                                    </div>
                                    <div className="status-icons">
                                        <div className="remove-now"><img src={RemoveIcon} alt="" /></div>

                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="progress-indicator">
                                <div className="uploading">
                                    <div className="file-progress-wrap">
                                        <div className="filename">Tungchivo-avatar.png</div>
                                        <div className="file-progress">
                                            <div className="percent-width"></div>
                                        </div>
                                        <div className="size-percent">
                                            <div className="sizetxt">428KB</div>
                                            <div className="percent-txt">70%</div>
                                        </div>
                                    </div>
                                    <div className="status-icons">
                                        <div><img src={DoneIcon} alt="" /></div>
                                        <div><img src={RemoveIcon} alt="" /></div>

                                    </div>
                                </div>
                                <div className="uploaded">
                                    <div className="filename-txt">Tungchivo-avatar.png</div>
                                    <div className="size-msg">
                                        428KB
                                        <img src={RemoveIcon} alt="" />
                                    </div>
                                </div>
                            </div> */}
                        </Tab.Pane>
                        <Tab.Pane eventKey="mami_gallery">
                            <div className="search-gallery">
                                <div className="each-filter for-search">
                                    <input type="text"
                                        placeholder="Enter Product title or category"
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value)
                                        }}
                                        onBlur={(e) => {
                                            saveSearchFilter(e.target.value.trim())
                                        }} />
                                    <button>
                                        <img src={SearchIco} alt="" />
                                    </button>
                                </div>
                                {(pageProps.getImageGalleryRequest?.galleryData && pageProps.getImageGalleryRequest?.galleryData.length >= 1) &&
                                    <div>
                                        <div className="gallery-head">
                                            <h3>MamiMarket Gallery</h3>
                                            <div className="gallery-txt">Choose from thousands of stunning images..</div>
                                            {/* {
                                                pageProps.getImageGalleryRequest?.request_data?.response?.pageNumber
                                            }
                                            {
                                                pageProps.getImageGalleryRequest?.request_data?.response?.totalNumberOfPages
                                            } */}
                                        </div>
                                        <div className="filter-wrap">
                                            <label htmlFor="">Filter by:</label>
                                            <div className="filter-options">
                                                <Select
                                                    value={categoryChosen}
                                                    onChange={saveChosenCat}
                                                    options={catListItems}
                                                    placeholder="Category"
                                                    className="select-wrap"
                                                    classNamePrefix="gallery-cats"
                                                />

                                            </div>

                                        </div>
                                        <div id="scrollableDiv" style={{ height: 300, overflow: "auto" }}>
                                            <InfiniteScroll
                                                dataLength={pageProps.getImageGalleryRequest?.request_data?.response?.totalNumberOfPages * sizeofFetch}
                                                // dataLength={pageProps.getImageGalleryRequest.request_data.response.totalNumberOfRecords}
                                                next={() => {

                                                    let payload = `page=${pageProps.getImageGalleryRequest?.request_data?.response?.totalNumberOfPages + 1}&size=${sizeofFetch}`;
                                                    if (categoryChosen?.label) {
                                                        payload = `${payload}&fileName=${categoryChosen?.label}`;
                                                    }
                                                    if (searchTerm) {
                                                        payload = `${payload}&fileName=${searchTerm}`;
                                                    }


                                                    getMoreImages({ pageProps, payload })
                                                }}
                                                // hasMore={true}
                                                hasMore={
                                                    pageProps.getImageGalleryRequest?.request_data?.response?.pageNumber > pageProps.getImageGalleryRequest?.request_data?.response?.totalNumberOfPages
                                                }
                                                loader={<LoadingItem />}
                                                scrollableTarget="scrollableDiv"
                                            >
                                                {pageProps.getImageGalleryRequest?.galleryData.map((image: any, index: any) => (
                                                    // {pageProps.getImageGalleryRequest?.request_data?.response?.results.map((image: any, index: any) => (
                                                    <div className={`each-image each-image-xy-${index} `} key={index}
                                                        // style={{
                                                        //     backgroundImage: `url(${image.imageUrl})`
                                                        // }}
                                                        onClick={(e) => {
                                                            imagesChosen = [...setImagesUploaded];

                                                            if (!imagesChosen.find((eachImage: any) => eachImage.url === image.imageUrl)) {
                                                                imagesChosen.push({
                                                                    url: image.imageUrl,
                                                                    index,
                                                                    othernames: image?.otherNames ? [...image?.otherNames] : []
                                                                })
                                                                setImagesUploadedStore(imagesChosen)
                                                            } else {
                                                                removeImageFromUploads(image.imageUrl)
                                                                // imagesChosen = imagesChosen.filter((itemData: any) => {
                                                                //     return itemData.url !== image.imageUrl;
                                                                // });
                                                            }


                                                            document.querySelector(`.each-image-xy-${index}`)?.classList?.toggle("chosen-image")

                                                        }}>

                                                        <img
                                                            src={image.imageUrl} alt=""
                                                            className="gallery-img" />

                                                    </div>
                                                ))}

                                            </InfiniteScroll>
                                        </div>

                                    </div>
                                }
                                {pageProps.getImageGalleryRequest.request_status === "GET_IMAGE_GALLERY_PENDING" &&
                                    <LoadingItem />
                                }
                                {pageProps.getImageGalleryRequest.request_status === "GET_IMAGE_GALLERY_FAILURE" &&
                                    <AlertMsg type="error" message={pageProps.getImageGalleryRequest?.request_data.error} />
                                }
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>


            </Modal.Body>
            {(pageProps.getImageGalleryRequest.request_status === "GET_IMAGE_GALLERY_SUCCESS" ||
                pageProps.getImageGalleryRequest.request_status === "GET_IMAGE_GALLERY_NEWBATCH") &&
                <Modal.Footer>
                    <button onClick={() => {
                        // if (imagesChosen.length >= 1) {
                        //     // setSelectedImagesStore(imagesChosen)
                        //     hideProductImgModal()
                        // }
                        uploadImagesData.length = 0;

                        uploadImagesData.push(...setImagesUploaded)
                        hideProductImgModal();

                    }}> Add to Product</button>
                </Modal.Footer>
            }
        </Modal>
    )
}

const EditProductForm = ({ pageProps }: any) => {
    let productInfo = pageProps.getAProductRequest.request_data.response;
    const [showImagesModal, setShowImagesModal] = useState(false);
    const [isCatError, setShowCatError] = useState(false);
    const [isImagesError, setNoImages] = useState(false);
    const [newPhotoStatus, setNewPhotoStatus] = useState<any>();
    const [initialValues, setInitialValues] = useState<any>();

    const [allCatsList, setAllCatsList] = useState<any[]>([]);
    const [allCatsSelected, setAllCatsSelected] = useState<any[]>([]);
    const [isDiscountMore, setIsDiscountMore] = useState(false);
    const [isTaxableOption, setIsTaxable] = useState(pageProps.getAProductRequest.request_data.response?.isTaxable);

    const [startDate, onStartChange] = useState<any>();
    const [endDate, onEndChange] = useState<any>(!productInfo?.discountPrice ? "" : new Date(productInfo?.discountPrice?.endDate));

    const [selecteCatsStore, setSelecteCatsStore] = useState<any[]>([...pageProps.getAProductRequest.request_data.response.categories]);
    const [updatedCatList, setUpdatedCatList] = useState<any>([...pageProps.getAProductRequest.request_data.response.categories])

    const [selectedImagesStore, setSelectedImagesStore] = useState<any>(() => {
        let initImages: any[] = [];

        pageProps.getAProductRequest.request_data.response.urls.forEach((eachImage: any, index: any) => {
            initImages.push({ url: eachImage, index })
            // uploadImagesData.push({ url: eachImage, index })
        })


        return initImages;
    });
    const [catListItems, setCatListItems] = useState<any>();
    const { getImageGalleryReducer } = useSelector((state: any) => state.allProductReducers);
    const { productId } = useParams();
    // let initImages: any[] = [];



    // let categoriesChosen: any = [...productInfo.categories];
    let imagesChosen: any = [],
        categoriesList = [...productInfo.categories]


    const checkForCategory = (cat: any) => {
        if (categoriesList.find((eachCat: any) => eachCat === cat)) {
            return true;
        }
        return false;
    }
    // useEffect(() => {
    //     imagesChosen.length = 0;
    //     productInfo.urls.map((eachImg: any, index: any) => {

    //         imagesChosen.push({
    //             index,
    //             url: eachImg
    //         })
    //         if (index === productInfo?.urls.length - 1) {
    //             setSelectedImagesStore(imagesChosen)
    //         }
    //     })




    // }, [])



    useEffect(() => {
        let catsData: any[] = productInfo.categories;
        let allCatAvailable = pageProps.getProductMetaDataRequest.request_data.response.categories

        let catFormated: any[] = catsData.map((each: any) => { return { label: each, value: each } })
        let catAvailableFormated: any[] = allCatAvailable.map((each: any) => { return { label: each?.name, value: each?.name } })

        setAllCatsSelected(catFormated)

        setAllCatsList(catAvailableFormated)
        let formattedStart: any, formattedEnd: any;

        if (productInfo?.discountPrice?.startDate && productInfo?.discountPrice?.endDate) {
            formattedStart = new Date(productInfo?.discountPrice?.startDate);
            // formattedStart.setDate(formattedStart.getDate() - 1);
            formattedStart.setDate(formattedStart.getDate());
            onStartChange(formattedStart)
            // formattedStart  = formattedStart.toISOString();

            formattedEnd = new Date(productInfo?.discountPrice?.endDate);
            // formattedEnd.setDate(formattedEnd.getDate() - 1);
            formattedEnd.setDate(formattedEnd.getDate());
            // formattedEnd  = formattedEnd.toISOString();

            onEndChange(formattedEnd)
            
            
        }

        let existingDiff, exisitingPerCentOffer = 0;
        if (productInfo?.discountPrice) {
            existingDiff = parseFloat(productInfo?.price?.amount) - productInfo?.discountPrice?.amount;
            exisitingPerCentOffer = Math.round((existingDiff / parseFloat(productInfo?.price?.amount)) * 100);
        }

       
        let initialValuesData: any = {
            productName: productInfo.name,
            productPrice: productInfo?.price?.amount.toString(),
            // productPrice: "",
            productSize: productInfo.sizePerUnit.toString(),
            productQty: productInfo.availableQuantity.toString(),
            productUnit: productInfo.unitOfMeasurementId >= 1 ? productInfo.unitOfMeasurementId.toString() : "",
            // productStatus: "",
            productDesc: productInfo.description,

            productDiscountPercent: !productInfo?.discountPrice ? "" : exisitingPerCentOffer.toString(),
            productDiscountPrice: !productInfo?.discountPrice ? "" : productInfo?.discountPrice?.amount.toString(),
            productDiscountStart: formattedStart ? formattedStart.toISOString() : "",
            productDiscountEnd: formattedEnd ? formattedEnd.toISOString() : "",
            // startDate: !productInfo?.discountPrice ? "" : productInfo?.discountPrice?.startDate,
            // endDate: "",

            addDiscount: !productInfo?.discountPrice ? false : true,
        }

        setInitialValues(initialValuesData)



    }, [])

    useEffect(() => {


        if (newPhotoStatus === "yes" && uploadImagesData.length >= 1) {
            setSelectedImagesStore(uploadImagesData)
        } else {
            setSelectedImagesStore(selectedImagesStore)
        }
    }, [newPhotoStatus])

    const hideProductImgModal = () => {
        setNewPhotoStatus("yes")
        setShowImagesModal(false)
    }

    // const removeProductImage = (index: any) => {
    //     let imagesBatch = selectedImagesStore;
    //     imagesBatch = imagesBatch.filter((itemData: any) => {
    //         return itemData.index !== index;
    //     });
    //     setSelectedImagesStore(imagesBatch)
    // }

    const removeProductImage = (url: any) => {
        let imagesBatch = selectedImagesStore;

        imagesBatch = imagesBatch.filter((itemData: any) => {
            return itemData.url !== url;
        });
        uploadImagesData.length = 1;
        uploadImagesData.push(...imagesBatch)

        setSelectedImagesStore(imagesBatch)
    }



    let detailsValidationSchema = Yup.object().shape({
        productName: Yup.string()
            .required("Required"),
        // productPrice: Yup.string()
        //     .required("Required"),
        productSize: Yup.string()
            .required("Required"),
        productQty: Yup.string()
            .required("Required"),
        productUnit: Yup.string()
            .required("Required"),
        // productStatus: Yup.string()
        //     .required("Required"),
        productTags: Yup.string(),
        addDiscount: Yup.boolean(),
        productDiscountPercent: Yup.string().when("addDiscount", {
            is: (addDiscount: boolean) => addDiscount === true,
            then: Yup.string().required('Required')
        }),
        productDiscountPrice: Yup.string().when("addDiscount", {
            is: (addDiscount: boolean) => addDiscount === true,
            then: Yup.string().required('Required')
        }),
        productDiscountStart: Yup.string().when("addDiscount", {
            is: (addDiscount: boolean) => addDiscount === true,
            then: Yup.string().required('Required')
        }),
        productDiscountEnd: Yup.string().when("addDiscount", {
            is: (addDiscount: boolean) => addDiscount === true,
            then: Yup.string().required('Required')
        }),
        productDesc: Yup.string()
            .required("Required")


    });





    // let existingDiff, exisitingPerCentOffer;
    //     if (productInfo?.discountPrice) {
    //         existingDiff = parseFloat(productInfo?.price?.amount) - productInfo?.discountPrice?.amount;
    //             exisitingPerCentOffer = Math.round((existingDiff / parseFloat(productInfo?.price?.amount)) * 100);
    //     }

    
    // let initialValues: any = {
    //     productName: productInfo.name,
    //     productPrice: productInfo?.price?.amount.toString(),
    //     // productPrice: "",
    //     productSize: productInfo.sizePerUnit.toString(),
    //     productQty: productInfo.availableQuantity.toString(),
    //     productUnit: productInfo.unitOfMeasurementId >= 1 ? productInfo.unitOfMeasurementId.toString() : "",
    //     // productStatus: "",
    //     productDesc: productInfo.description,

    //     productDiscountPercent: !productInfo?.discountPrice ? "" : exisitingPerCentOffer,
    //     productDiscountPrice: !productInfo?.discountPrice ? "" : productInfo?.discountPrice?.amount.toString(),
    //     productDiscountStart: startDate ? startDate.toISOString(): "",
    //     productDiscountEnd: endDate ? endDate.toISOString(): "",
    //     // startDate: !productInfo?.discountPrice ? "" : productInfo?.discountPrice?.startDate,
    //     // endDate: "",

    //     addDiscount: !productInfo?.discountPrice ? false : true,
    // }
    

    return (

        <div>
            {/* {showImagesModal && <ProductImagePop />} */}

            {showImagesModal && <ProductImagePopUp
                pageProps={pageProps}
                showImagesModal={showImagesModal}
                catListItems={catListItems}
                hideProductImgModal={hideProductImgModal}
                tempImages={selectedImagesStore}
            />}

            {/* {pageProps.getProductMetaDataRequest.request_status === "GET_PRODUCT_META_SUCCESS" && */}
            <div className="product-form">
                {initialValues &&
                    <Formik
                        initialValues={initialValues}
                        validationSchema={detailsValidationSchema}
                        onSubmit={(values: any, actions) => {
                            let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
                            loggedAccountInfo = JSON.parse(loggedAccountInfo);

                            let urlsList = selectedImagesStore.map((img: any) => img.url);
                            let existingOtherNames = productInfo?.otherNames ? productInfo?.otherNames.split(",") : [""]
                            let namesStores: any[] = [];
                            let otherNamesList = selectedImagesStore.map((names: any) => {
                                let nameList: any[];

                                if (names?.othernames && names?.othernames.length >= 1) {


                                    let nameListTemp = names?.othernames[0].split(",") || [""];


                                    nameListTemp.forEach((eachItem: any) => {

                                        // nameList = eachItem.map((eachName: any) => eachName )
                                        namesStores.push(eachItem)
                                        return eachItem;
                                        // return nameList.toString();
                                    })



                                }
                                if (names?.otherNames) {
                                    nameList = names.otherNames.map((eachName: any) => eachName)
                                    return nameList.toString();
                                }

                            });



                            // otherNamesList.push(...existingOtherNames)
                            namesStores.push(...existingOtherNames)
                            namesStores = namesStores.filter((element: any, index: any) => {
                                return namesStores.indexOf(element) === index;
                            });
                            namesStores = namesStores.filter(function (el: any) {
                                return el != null && el != "";
                            });

                            // if (updatedCatList.length >= 1) {
                            if (allCatsSelected.length >= 1) {
                                setShowCatError(false)
                            } else {
                                setShowCatError(true)
                            }
                            if (urlsList.length >= 1) {
                                setNoImages(false)
                            } else {
                                setNoImages(true)
                            }

                            

                            // if (updatedCatList.length >= 1 && urlsList.length >= 1) {
                            if (allCatsSelected.length >= 1 && urlsList.length >= 1) {
                                setShowCatError(false)
                                let chosenCats = allCatsSelected.map((cat: any) => cat.value);
                                let formattedStart: any, formattedEnd: any;

                                
                                
                                if (startDate && endDate) {
                                    formattedStart = new Date(startDate.toISOString());
                                    // formattedStart.setDate(formattedStart.getDate() + 1);
                                    formattedStart.setDate(formattedStart.getDate());
                                    formattedStart = formattedStart.toISOString();

                                    formattedEnd = new Date(endDate.toISOString());
                                    // formattedEnd.setDate(formattedEnd.getDate() + 1);
                                    formattedEnd.setDate(formattedEnd.getDate());
                                    formattedEnd = formattedEnd.toISOString();

                                    
                                    
                                }

                                let payload = {
                                    id: productId,
                                    storeId: loggedAccountInfo.selectedStore.id,
                                    name: values.productName.trim(),
                                    categories: [...chosenCats],
                                    urls: [...urlsList],
                                    otherNames: [...namesStores],
                                    description: values.productDesc.trim(),
                                    availableQuantity: parseInt(values.productQty),
                                    unitOfMeasurementId: parseInt(values.productUnit),
                                    sizePerUnit: parseFloat(values.productSize.replace(/,/g, '')),
                                    price: {
                                        amount: parseFloat(values.productPrice.replace(/,/g, '')),
                                        // isDiscount: false
                                    },
                                    isTaxable: isTaxableOption,
                                    discountPrice: values.addDiscount ? {
                                        amount: parseFloat(values.productDiscountPrice.replace(/,/g, '')),
                                        startDate: formattedStart,
                                        endDate: formattedEnd
                                    } : null
                                }


                                

                                editProduct({ pageProps, payload })
                            }





                        }}
                    >
                        {(props: FormikProps<any>) => {
                            const {
                                values,
                                touched,
                                errors,
                                setFieldTouched,
                                setFieldError,
                                handleChange,
                                setFieldValue,
                            } = props;
                            return (
                                <Form>

                                    <div className="form-wrap">

                                        <div className="right-product-form">
                                            <div className="product-images-container">
                                                <div className={(selectedImagesStore.length >= 1) ? "image-control" : "image-control full"}>
                                                    <div className="images-label">Product image</div>
                                                    <div className="product-images" onClick={() => {
                                                        let allCatData: any = [];
                                                        pageProps.getProductMetaDataRequest.request_data.response.categories.forEach((eachCat: any) => {
                                                            allCatData.push({
                                                                value: eachCat.name,
                                                                label: eachCat.name
                                                            })
                                                        })
                                                        imagesChosen.length = 0;

                                                        setCatListItems(allCatData)
                                                        let payload = `page=1&size=${sizeofFetch}`;
                                                        getImagesGallery({ pageProps, payload })
                                                        setNewPhotoStatus("no")
                                                        setShowImagesModal(true)
                                                    }}>
                                                        <div className="upload-wrap-content">
                                                            <img src={AddImg} alt="" />
                                                            <div className="upload-txt">Check <span>MamiMarkets Gallery</span>  or <span>Upload from your device</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    // (!showImagesModal && selectedImagesStore.length >= 1) &&
                                                    (selectedImagesStore.length >= 1) &&
                                                    <div className="all-images-chosen">

                                                        {
                                                            selectedImagesStore.map((eachImage: any, index: any) => {
                                                                return (
                                                                    <div className="product-image-wrap" key={index}>
                                                                        <div className="image-actions">
                                                                            <div className="select-cover">
                                                                                <input type="radio" name="cover-image-select" />
                                                                            </div>
                                                                            <div className="deleteicon" onClick={() => removeProductImage(eachImage.url)}>
                                                                                <img src={TrashIcon} alt="" className="" />
                                                                            </div>

                                                                        </div>
                                                                        <div className="product-image-item"
                                                                            key={index}
                                                                        // style={{
                                                                        //     backgroundImage: `url(${eachImage.url})`
                                                                        // }}
                                                                        >
                                                                            <img src={eachImage.url} alt="" />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <div className="uploads-msg">
                                                Ensure you use high-quality images that clearly showcase the product. Also note that pictures must be in 450 x 450 dimension. Max file size of 5mb.
                                            </div>
                                            <div className="add-discount-wrap">
                                                <Field onChange={handleChange} type="checkbox" name="addDiscount" id="addDiscount" />
                                                <label htmlFor="addDiscount">Add a discount</label>
                                            </div>
                                            {values.addDiscount === true &&
                                                <div className="discount-wrap">
                                                    <div className="two-sided">
                                                        <div className="form-group">
                                                            <div className={errors.productDiscountPercent && touched.productDiscountPercent ? "invalid-input-wrap " : ""}>
                                                                <label htmlFor="productDiscountPercent">
                                                                    Discount %
                                                                    {values.productPrice &&
                                                                        <ErrorMessage name="productDiscountPercent" className="form-input-error" component="span" />
                                                                    }
                                                                    {!values.productPrice &&
                                                                        <span className="price-info-txt">(Enter original price)</span>
                                                                    }
                                                                </label>

                                                                <Field
                                                                    value={allowNumbersOnly(values.productDiscountPercent, 3)}
                                                                    name="productDiscountPercent"
                                                                    type="text"
                                                                    autoComplete="off"
                                                                    placeholder="Discount %"

                                                                    disabled={!values.productPrice}
                                                                    // onChange={handleChange}
                                                                    onChange={(e: any) => {
                                                                        let valueSet: any = numberWithCommas(e.target.value)

                                                                        if (parseFloat(valueSet) <= 100 || e.target.value === "") {
                                                                            setFieldTouched("productDiscountPercent", true, true)
                                                                            setFieldValue("productDiscountPercent", valueSet)

                                                                            let originalPrice: any = parseFloat(values.productPrice.replace(/,/g, ''));
                                                                            valueSet = valueSet.replace(/,/g, '');


                                                                            let newProductPrice: any = parseFloat(valueSet) / 100 * parseFloat(originalPrice);
                                                                            newProductPrice = parseFloat(originalPrice) - newProductPrice;
                                                                            newProductPrice = Number.parseFloat(newProductPrice.toString()).toFixed(2);


                                                                            setFieldValue("productDiscountPrice", newProductPrice.toString())



                                                                        }



                                                                    }}
                                                                    className={`input-wrap inapp-input ${errors.productDiscountPercent && touched.productDiscountPercent ?
                                                                        "is-invalid-input "
                                                                        : !errors.productDiscountPercent && values.productDiscountPercent !== "" ? "valid-input" : ""}`}
                                                                >

                                                                </Field>

                                                            </div>

                                                        </div>
                                                        <div className="form-group">
                                                            <div className={((errors.productDiscountPrice && touched.productDiscountPrice) || isDiscountMore) ? "invalid-input-wrap " : ""}>
                                                                <label htmlFor="productDiscountPrice">
                                                                    New price
                                                                    {(errors.productDiscountPrice && touched.productDiscountPrice) &&
                                                                        <ErrorMessage name="productDiscountPrice" className="form-input-error" component="span" />
                                                                    }
                                                                    {!values.productPrice &&
                                                                        <span className="price-info-txt">(Enter original price)</span>
                                                                    }
                                                                </label>

                                                                <div className="price-wrap">
                                                                    <span className="currency-pre">$</span>
                                                                    <Field
                                                                        value={values.productDiscountPrice}
                                                                        name="productDiscountPrice"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        // onChange={handleChange}
                                                                        disabled={!values.productPrice}
                                                                        onChange={(e: any) => {
                                                                            let valueSet: any = numberWithCommas(e.target.value)
                                                                            setFieldTouched("productDiscountPrice", true, true)


                                                                            setFieldValue("productDiscountPrice", valueSet)

                                                                            if (parseFloat(valueSet) >= parseFloat(values.productPrice)) {
                                                                                setIsDiscountMore(true)
                                                                                setFieldValue("productDiscountPercent", "")

                                                                            } else {
                                                                                setIsDiscountMore(false)
                                                                                let originalPrice: any = parseFloat(values.productPrice.replace(/,/g, ''));
                                                                                valueSet = valueSet.replace(/,/g, '');

                                                                                let diff = parseFloat(originalPrice) - parseFloat(valueSet),
                                                                                    perCentOffer = Math.round((diff / parseFloat(originalPrice)) * 100);

                                                                                setFieldValue("productDiscountPercent", perCentOffer.toString())


                                                                            }
                                                                            // setFieldError



                                                                        }}
                                                                        className={`input-wrap inapp-input ${((errors.productDiscountPrice && touched.productDiscountPrice) || isDiscountMore) ?
                                                                            "is-invalid-input "
                                                                            : !errors.productDiscountPrice && values.productDiscountPrice !== "" ? "valid-input" : ""}`}
                                                                    >

                                                                    </Field>
                                                                </div>
                                                                {isDiscountMore && <div className="form-input-error">*Discount must be less than price</div>}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="two-sided">
                                                        <div className="form-group">
                                                            <div className={errors.productDiscountStart && touched.productDiscountStart ? "invalid-input-wrap " : ""}>
                                                                <label htmlFor="productDiscountStart">
                                                                    Start date
                                                                    <ErrorMessage name="productDiscountStart" className="form-input-error" component="span" />
                                                                </label>

                                                                <DatePicker
                                                                    selected={startDate}
                                                                    onChange={(date: any) => {
                                                                        
                                                                        onStartChange(date)
                                                                        setFieldTouched("productDiscountStart", true)
                                                                        setFieldValue("productDiscountStart", date.toISOString())
                                                                    }}
                                                                    minDate={new Date()}
                                                                    name="productDiscountStart"
                                                                    // maxDate={new Date()}
                                                                    placeholderText="Select start date"
                                                                />
                                                            </div>

                                                        </div>
                                                        <div className="form-group">
                                                            <div className={errors.productDiscountEnd && touched.productDiscountEnd ? "invalid-input-wrap " : ""}>
                                                                <label htmlFor="productDiscountEnd">
                                                                    End date
                                                                    <ErrorMessage name="productDiscountEnd" className="form-input-error" component="span" />
                                                                </label>
                                                                <DatePicker
                                                                    selected={endDate}
                                                                    onChange={(date: any) => {
                                                                        onEndChange(date)
                                                                        setFieldTouched("productDiscountEnd", true)
                                                                        setFieldValue("productDiscountEnd", date.toISOString())
                                                                    }}
                                                                    name="productDiscountEnd"

                                                                    minDate={new Date()}
                                                                    // maxDate={new Date()}
                                                                    placeholderText="Select end date"
                                                                />

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {pageProps?.updateStoreProductRequest?.request_status
                                                && pageProps.updateStoreProductRequest.request_status === "UPDATE_PRODUCT_FAILURE" &&
                                                <AlertMsg type="error" message={pageProps.updateStoreProductRequest?.request_data.error} />
                                            }
                                            {isCatError &&
                                                <AlertMsg type="error" message="No Category Selected" />
                                            }
                                            {isImagesError &&
                                                <AlertMsg type="error" message="No Images Selected" />
                                            }

                                            <div className="product-cta">
                                                <button
                                                    disabled={pageProps.updateStoreProductRequest?.is_request_processing || updatedCatList.length < 1}
                                                    className={(selectedImagesStore.length >= 1) ? "btn" : "btn btn-disabled"}>
                                                    {pageProps.updateStoreProductRequest.is_request_processing ? "Please wait..." : "Update Product"}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="product-info">
                                            <div className="form-group">
                                                <div className={errors.productName && touched.productName ? "invalid-input-wrap" : ""}>
                                                    <label htmlFor="productName">
                                                        Product name
                                                        <ErrorMessage name="productName" className="form-input-error" component="span" />
                                                    </label>
                                                    <Field
                                                        value={values.productName}
                                                        name="productName"
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="Product Title"
                                                        onChange={handleChange}
                                                        className={`input-wrap inapp-input ${errors.productName && touched.productName ?
                                                            "is-invalid-input "
                                                            : !errors.productName && values.productName !== "" ? "valid-input" : ""}`}
                                                    >

                                                    </Field>
                                                    <small className="help-text">Maximum of 50 characters</small>
                                                </div>

                                            </div>
                                            <div className="form-group">
                                                <div className="">

                                                    <label htmlFor="productName">Category</label>
                                                    <MultiSelect
                                                        options={allCatsList}
                                                        value={allCatsSelected}
                                                        onChange={setAllCatsSelected}
                                                        labelledBy="Select"
                                                        hasSelectAll={false}
                                                        className="cat-select-wrap"

                                                    />
                                                </div>

                                            </div>
                                            <div className="two-sided">
                                                <div className="form-group">
                                                    <div className={errors.productPrice && touched.productPrice ? "invalid-input-wrap" : ""}>
                                                        <label htmlFor="productPrice">
                                                            Price
                                                            <ErrorMessage name="productPrice" className="form-input-error" component="span" />
                                                        </label>
                                                        <div className="price-wrap">
                                                            <span className="currency-pre">$</span>
                                                            <Field
                                                                value={numberWithCommas(values.productPrice)}
                                                                name="productPrice"
                                                                type="text"
                                                                autoComplete="off"
                                                                // onChange={handleChange}
                                                                onChange={(e: any) => {
                                                                    let valueSet: any = numberWithCommas(e.target.value)
                                                                    setFieldTouched("productPrice", true, true)


                                                                    setFieldValue("productPrice", valueSet)
                                                                    if (!e.target.value) {
                                                                        setFieldValue("productDiscountPercent", "")
                                                                        setFieldValue("productDiscountPrice", "")
                                                                    } else {

                                                                        if (values.productDiscountPercent) {
                                                                            let disCountPercentvalue: any = values.productDiscountPercent.replace(/,/g, ''),
                                                                                originalPrice: any = parseFloat(valueSet.replace(/,/g, ''));


                                                                            if (parseFloat(disCountPercentvalue) <= 100) {



                                                                                let newProductPrice: any = parseFloat(disCountPercentvalue) / 100 * originalPrice;
                                                                                newProductPrice = originalPrice - newProductPrice;
                                                                                newProductPrice = Number.parseFloat(newProductPrice.toString()).toFixed(2);


                                                                                setFieldValue("productDiscountPrice", newProductPrice)

                                                                            }

                                                                        }


                                                                    }
                                                                }}
                                                                placeholder="Price"
                                                                // disabled={true}

                                                                className={`input-wrap inapp-input ${errors.productPrice && touched.productPrice ?
                                                                    "is-invalid-input "
                                                                    : !errors.productPrice && values.productPrice !== "" ? "valid-input" : ""}`}
                                                            >

                                                            </Field>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="productSize">
                                                        Size
                                                        <ErrorMessage name="productSize" className="form-input-error" component="span" />
                                                    </label>
                                                    <div className={((errors.productSize && touched.productSize) || (errors.productUnit)
                                                    ) ? "invalid-input qty-input" : "qty-input"}>
                                                        <div className="input-item">
                                                            <Field
                                                                value={numberWithCommas(values.productSize)}
                                                                name="productSize"
                                                                type="text"
                                                                autoComplete="off"
                                                                placeholder="Product Size"
                                                                onChange={handleChange}
                                                                className={` ${errors.productSize && touched.productSize ?
                                                                    "is-invalid-input "
                                                                    : !errors.productSize && values.productSize !== "" ? "valid-input" : ""}`}
                                                            >

                                                            </Field>
                                                        </div>
                                                        <Field
                                                            value={values.productUnit}
                                                            name="productUnit"
                                                            as="select"
                                                            autoComplete="off"
                                                            onChange={handleChange}
                                                            placeholder=""
                                                            className={`${errors.productUnit && touched.productUnit ?
                                                                "is-invalid-input"
                                                                : !errors.productUnit && values.productUnit !== "" ? "valid-input" : ""}`}
                                                        >
                                                            <option value="">pick</option>
                                                            {
                                                                pageProps.getProductMetaDataRequest.request_data.response.units.map((eachUnit: any, index: any) => {

                                                                    return (
                                                                        <option key={index} value={eachUnit.id}>{eachUnit.unit}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="productSize" className="form-input-error" component="div" />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="productQty">
                                                    Quantity Available
                                                    <ErrorMessage name="productQty" className="form-input-error" component="span" />
                                                </label>
                                                <div className={errors.productQty && touched.productQty ? "invalid-input-wrap" : ""}>
                                                    <Field
                                                        value={allowNumbersOnly(values.productQty)}
                                                        name="productQty"
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="Quantity Available"
                                                        onChange={handleChange}
                                                        className={`input-wrap inapp-input ${errors.productQty && touched.productQty ?
                                                            "is-invalid-input "
                                                            : !errors.productQty && values.productQty !== "" ? "valid-input" : ""}`}
                                                    >

                                                    </Field>
                                                </div>
                                                <ErrorMessage name="productQty" className="form-input-error" component="div" />
                                            </div>

                                            <div className="form-group">
                                                <div>
                                                    <label htmlFor="productDesc">
                                                        Description
                                                        <ErrorMessage name="productDesc" className="form-input-error" component="span" />
                                                    </label>
                                                    <Field
                                                        value={values.productDesc}
                                                        name="productDesc"
                                                        as="textarea"
                                                        autoComplete="off"
                                                        placeholder="Product Description"
                                                        onChange={handleChange}
                                                        className={`input-wrap inapp-input ${errors.productDesc && touched.productDesc ?
                                                            "is-invalid-input "
                                                            : !errors.productDesc && values.productDesc !== "" ? "valid-input" : ""}`}
                                                    >

                                                    </Field>
                                                    <small className="help-text">Maximum of 500 characters</small>
                                                </div>
                                                <ErrorMessage name="productDesc" className="form-input-error" component="div" />
                                            </div>
                                            <div className="form-group">
                                                <div className="tax-label">Is this product taxable? <span>Learn more</span> </div>
                                                <div className="all_options">
                                                    <div className="an_option">
                                                        <span className={` custom_radio  ${isTaxableOption ? "selected_option" : ""}`}>
                                                            <input onChange={(e) => {}} name="check_taxable" type="radio" id="isTaxable" value="isTaxable" />
                                                            {/* <input onChange={(e) => setIsTaxable(true)} name="check_taxable" type="radio" id="isTaxable" value="isTaxable" /> */}
                                                        </span>
                                                        <label htmlFor="isTaxable">
                                                            <span>Yes</span>

                                                        </label>
                                                    </div>
                                                    <div className="an_option">
                                                        <span className={`custom_radio ${!isTaxableOption ? "selected_option" : ""}`}>
                                                            <input onChange={(e) => {}} name="check_taxable" type="radio" id="notTaxable" value="notTaxable" />
                                                            {/* <input onChange={(e) => setIsTaxable(false)} name="check_taxable" type="radio" id="notTaxable" value="notTaxable" /> */}
                                                        </span>
                                                        <label htmlFor="notTaxable">
                                                            <span>No</span>

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className={errors.productTags && touched.productTags ? "invalid-input-wrap" : ""}>
                                                    <label htmlFor="productTags">
                                                        Product tags (optional)
                                                        <ErrorMessage name="productTags" className="form-input-error" component="span" />
                                                    </label>
                                                    <Field
                                                        value={values.productTags}
                                                        name="productTags"
                                                        id="productTags"
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="Enter product name here"
                                                        onChange={handleChange}
                                                        className={`input-wrap inapp-input ${errors.productTags && touched.productTags ?
                                                            "is-invalid-input "
                                                            : !errors.productTags && values.productTags !== "" ? "valid-input" : ""}`}
                                                    >

                                                    </Field>

                                                </div>

                                            </div>



                                        </div>

                                    </div>



                                </Form>
                            )
                        }}

                    </Formik>
                }
            </div>
            {/* } */}
            {pageProps.getProductMetaDataRequest.request_status === "GET_PRODUCT_META_PENDING" &&
                <LoadingItem />
            }
        </div>

    )
}

const MainHeader = () => {
    return (
        <div className="product-info-header">
            <div className="left-nav" onClick={() => history.replace("/app/my-products")}> <img src={LeftArrow} alt="" /> </div>
            <div className="other-item-wrap">
                <div className="heading-text">Edit Product</div>
                <div className="heading-msg">Edit your product to your store by filling out the necessary information below</div>
            </div>
        </div>
    )
}


const InAppContent = ({ pageProps }: any) => {
    const { productId } = useParams();

    useEffect(() => {
        if (productId) {
            getMetadata({
                pageProps,
                // payload: "CLEAR"
            })
            getImagesGallery({
                pageProps,
                payload: "CLEAR"
            })

            getProductDetail({
                pageProps,
                payload: productId
            })

            addNewProduct({
                pageProps,
                payload: "CLEAR"
            })
            editProduct({
                pageProps,
                payload: "CLEAR"
            })
        } else {
            history.replace("/app/my-products")
        }


    }, [])

    return (
        <div className="inapp-content">
            <div className="page-content form-page">

                {/* <div className="addproduct-heading">
                    <h3>Edit Product</h3>
                    <div className="addproduct-top">Get the right market for your products. Edit your products here and get set to sell them on mamiMarket</div>
                </div> */}
                {(pageProps.getAProductRequest.request_status === "GET_A_PRODUCT_SUCCESS" &&
                    pageProps.getProductMetaDataRequest.request_status === "GET_PRODUCT_META_SUCCESS") &&
                    <EditProductForm pageProps={pageProps} />
                }
                {pageProps.getAProductRequest.request_status === "GET_A_PRODUCT_PENDING" &&
                    <LoadingItem />
                }
                {pageProps.getAProductRequest.request_status === "GET_A_PRODUCT_FAILURE" &&
                    <AlertMsg type="error" message={pageProps.getAProductRequest?.request_data.error} />
                }
                {pageProps.getProductMetaDataRequest.request_status === "GET_A_PRODUCT_FAILURE" &&
                    <AlertMsg type="error" message={pageProps.getProductMetaDataRequest?.request_data.error} />
                }
            </div>
        </div>
    )
}
const EditProduct = (pageProps: any) => {
    // const { productId } = useParams();
    // useEffect(() => {


    //         getProductDetail({
    //             pageProps,
    //             payload: "CLEAR"
    //         })



    // }, [])
    return (
        <InAppTemplate pageHeadingTitle="Edit Product" showMainPageHeading={true} mainPageHeading={<MainHeader />} childComponent={<InAppContent pageProps={pageProps} />} />
    )
}

const mapDispatchToProps = {
    updateProductAction: storeActions.UpdateStoreProduct,
    getMetadataAction: storeActions.GetProductMetaData,
    getImageGalleryAction: storeActions.GetImageGallery,
    getAProduct: storeActions.GetAProduct,
    uploadProductImageAction: storeActions.UploadProductImage,
};

const mapStateToProps = (state: any) => ({
    // return {
    // allImagesGallery: ()=>{
    //     return 
    // },
    getProductMetaDataRequest: state.allProductReducers.getProductMetaDataReducer,
    updateStoreProductRequest: state.allProductReducers.updateStoreProductReducer,
    getImageGalleryRequest: state.allProductReducers.getImageGalleryReducer,
    createStoreProductRequest: state.allProductReducers.createStoreProductReducer,
    getAProductRequest: state.allProductReducers.getAProductReducer,
    updateProductImageRequest: state.allProductReducers.updateProductImageReducer,

    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);